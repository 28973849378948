!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.timelineModule = t() : e.timelineModule = t();
}(self, () => (() => {
  "use strict";

  var e = {
      d: (t, n) => {
        for (var o in n) e.o(n, o) && !e.o(t, o) && Object.defineProperty(t, o, {
          enumerable: !0,
          get: n[o]
        });
      },
      o: (e, t) => Object.prototype.hasOwnProperty.call(e, t),
      r: e => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(e, "__esModule", {
          value: !0
        });
      }
    },
    t = {};
  function n(e) {
    return n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, n(e);
  }
  function o(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, r(o.key), o);
    }
  }
  function r(e) {
    var t = function (e) {
      if ("object" != n(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var o = t.call(e, "string");
        if ("object" != n(o)) return o;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == n(t) ? t : t + "";
  }
  e.r(t), e.d(t, {
    Timeline: () => Ve,
    TimelineCapShape: () => Se,
    TimelineClickEvent: () => ue,
    TimelineCursorType: () => be,
    TimelineDragEvent: () => me,
    TimelineDraggableData: () => T,
    TimelineElementType: () => w,
    TimelineEventSource: () => W,
    TimelineEvents: () => ke,
    TimelineEventsEmitter: () => i,
    TimelineInteractionMode: () => ge,
    TimelineKeyframeChangedEvent: () => V,
    TimelineKeyframeShape: () => l,
    TimelineScrollSource: () => Ce,
    TimelineSelectedEvent: () => ee,
    TimelineSelectionMode: () => we,
    TimelineStyleUtils: () => S,
    TimelineTimeChangedEvent: () => U,
    TimelineUtils: () => d,
    defaultGroupStyle: () => y,
    defaultRowHeight: () => v,
    defaultTimelineConsts: () => xe,
    defaultTimelineKeyframeStyle: () => p,
    defaultTimelineOptions: () => Pe,
    defaultTimelineRowStyle: () => _,
    defaultTimelineStyle: () => Me
  });
  var i = function () {
      return e = function e() {
        var t, n, o;
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, e), t = this, o = [], (n = r(n = "_subscriptions")) in t ? Object.defineProperty(t, n, {
          value: o,
          enumerable: !0,
          configurable: !0,
          writable: !0
        }) : t[n] = o;
      }, (t = [{
        key: "on",
        value: function (e, t) {
          return !!t && (this._subscriptions.push({
            topic: e,
            callback: t
          }), !0);
        }
      }, {
        key: "off",
        value: function (e, t) {
          var n = this._subscriptions.length;
          return this._subscriptions = this._subscriptions.filter(function (n) {
            return n && n.callback != t && n.topic != e;
          }), n !== this._subscriptions.length;
        }
      }, {
        key: "offAll",
        value: function () {
          this._subscriptions.length = 0;
        }
      }, {
        key: "emit",
        value: function (e, t) {
          this._subscriptions.forEach(function (n) {
            (null == n ? void 0 : n.topic) === e && null != n && n.callback && n.callback(t);
          });
        }
      }]) && o(e.prototype, t), Object.defineProperty(e, "prototype", {
        writable: !1
      }), e;
      var e, t;
    }(),
    l = function (e) {
      return e.None = "none", e.Rhomb = "rhomb", e.Circle = "circle", e.Rect = "rect", e;
    }({});
  function a(e) {
    return a = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, a(e);
  }
  function s(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, c(o.key), o);
    }
  }
  function u(e, t, n) {
    return (t = c(t)) in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function c(e) {
    var t = function (e) {
      if ("object" != a(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != a(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == a(t) ? t : t + "";
  }
  var f = [1, 2, 5, 10],
    d = function () {
      function e() {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, e);
      }
      return t = e, n = [{
        key: "drawLine",
        value: function (e, t, n, o, r) {
          e.moveTo(t, n), e.lineTo(o, r);
        }
      }, {
        key: "isNumber",
        value: function (e) {
          return !("number" != typeof e || !e && 0 !== e || isNaN(e) || !Number.isFinite(e));
        }
      }, {
        key: "deleteElement",
        value: function (e, t) {
          var n = e.indexOf(t);
          return -1 !== n ? e.splice(n, 1) : e;
        }
      }, {
        key: "isOverlap",
        value: function (e, t, n) {
          return !!n && n.x <= e && n.x + n.width >= e && n.y <= t && n.y + n.height >= t;
        }
      }, {
        key: "findGoodStep",
        value: function (t) {
          var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
            o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : f;
          if (o || (o = f), t <= 0 || isNaN(t) || !Number.isFinite(t)) return t;
          for (var r = t, i = null, l = e.getPowArgument(t), a = 0; a < o.length; a++) {
            var s = o[a] * Math.pow(10, l);
            if (!n || n % s == 0) {
              var u = e.getDistance(t, s);
              if (0 == u || u <= .1 && l > 0) {
                i = u, r = s;
                break;
              }
              (!i || i > u) && (i = u, r = s);
            }
          }
          return r;
        }
      }, {
        key: "keepInBounds",
        value: function (t) {
          var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null,
            o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null;
          return e.isNumber(t) && (e.isNumber(n) && (n || 0 === n) && (t = Math.max(t, n)), e.isNumber(o) && (o || 0 === o) && (t = Math.min(t, o))), t;
        }
      }, {
        key: "setMinMax",
        value: function (t, n) {
          var o = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
          if (!n || !t) return t;
          var r = n ? n.min : Number.NaN,
            i = t.min,
            l = (r || 0 === r) && e.isNumber(r),
            a = (i || 0 === i) && e.isNumber(i);
          l && a ? t.min = o ? Math.min(r, i) : Math.max(r, i) : l && (t.min = r);
          var s = n ? n.max : Number.NaN,
            u = t.max,
            c = (s || 0 === s) && e.isNumber(s),
            f = (u || 0 === u) && e.isNumber(u);
          return c && f ? t.max = o ? Math.max(s, u) : Math.min(s, u) : c && (t.max = s), t;
        }
      }, {
        key: "shrinkSelf",
        value: function (e, t) {
          return e ? (e.x -= t, e.y -= t, e.width += t, e.height += t, e) : e;
        }
      }, {
        key: "isRectIntersects",
        value: function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
          if (!e || !t) return console.log("Rectangles cannot be empty"), !1;
          var o = t.x + t.width,
            r = t.y + t.height;
          return n ? e.x <= o && t.x <= e.x + e.width && e.y <= r && t.y <= e.y + e.height : e.x < o && t.x < e.x + e.width && e.y < r && t.y < e.y + e.height;
        }
      }, {
        key: "getDistance",
        value: function (e, t, n, o) {
          return null != n && null != o ? Math.sqrt(Math.pow(e - n, 2) + Math.pow(t - o, 2)) : Math.abs(e - t);
        }
      }, {
        key: "sign",
        value: function (e) {
          return e >= 0 ? 1 : -1;
        }
      }, {
        key: "clearBrowserSelection",
        value: function () {
          if (window) if (window.getSelection) {
            var e = window.getSelection();
            e && e.removeAllRanges();
          } else {
            var t = window.document;
            t.selection && t.selection.empty();
          }
        }
      }, {
        key: "getPowArgument",
        value: function (e) {
          if (!e || 0 === e || !isFinite(e)) return 1;
          if (e >= 10 && e < 100) return 1;
          if (e >= 100 && e < 1e3) return 2;
          if (e >= 1e3 && e < 1e4) return 3;
          e = Math.abs(e);
          var t = 0,
            n = this.sign(e);
          if (e > 1) {
            for (; e >= 1;) e = Math.floor(e / 10), t++;
            return n * t - 1;
          }
          return e > 0 ? Math.floor(Math.log(e) / Math.log(10) + 1) - 1 : 1;
        }
      }, {
        key: "mergeOptions",
        value: function (t, n) {
          n = n || {};
          var o = e.cloneOptions(t);
          return function e(t, n) {
            if (t && n) for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && void 0 !== n[o] && ("object" === a(n[o]) ? "id" === o ? n[o] && n[o] !== t[o] && (t[o] = n[o]) : t[o] ? e(t[o], n[o]) : t[o] = n[o] : t[o] = n[o]);
          }(o, n), o;
        }
      }, {
        key: "timePadZero",
        value: function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 2,
            n = String(e),
            o = Math.pow(10, t - 1);
          if (e < o) for (; String(o).length > n.length;) n = "0".concat(e);
          return n;
        }
      }], null && s(t.prototype, null), n && s(t, n), Object.defineProperty(t, "prototype", {
        writable: !1
      }), t;
      var t, n;
    }();
  u(d, "deepClone", function (e) {
    return JSON.parse(JSON.stringify(e));
  }), u(d, "cloneOptions", function (e) {
    var t = JSON.parse(JSON.stringify(e, function (e, t) {
      return "id" === e ? void 0 : t;
    }));
    return t.id = e.id, t;
  });
  var v = 24,
    y = {
      height: "auto",
      marginTop: 4,
      fillColor: "#094771"
    },
    p = {
      fillColor: "DarkOrange",
      shape: l.Rhomb,
      selectedFillColor: "red",
      strokeColor: "black",
      selectedStrokeColor: "black",
      strokeThickness: .2,
      height: "auto",
      width: "auto"
    },
    _ = {
      height: v,
      marginBottom: 2,
      fillColor: "#252526",
      keyframesStyle: p,
      groupsStyle: y
    };
  function h(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, m(o.key), o);
    }
  }
  function m(e) {
    var t = function (e) {
      if ("object" != g(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != g(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == g(t) ? t : t + "";
  }
  function g(e) {
    return g = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, g(e);
  }
  var b = "undefined",
    S = function () {
      function e() {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, e);
      }
      return t = e, n = [{
        key: "getGroup",
        value: function (e) {
          return e && "string" == typeof e ? null : e || null;
        }
      }, {
        key: "getGroupStyle",
        value: function (t) {
          var n;
          return (null === (n = e.getGroup(t)) || void 0 === n ? void 0 : n.style) || null;
        }
      }, {
        key: "getFirstSet",
        value: function (t) {
          for (var n = arguments.length, o = new Array(n > 1 ? n - 1 : 0), r = 1; r < n; r++) o[r - 1] = arguments[r];
          return e.getValue.apply(e, [t, !1].concat(o));
        }
      }, {
        key: "getValue",
        value: function (t) {
          for (var n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1], o = [], r = !1, i = arguments.length, l = new Array(i > 2 ? i - 2 : 0), a = 2; a < i; a++) l[a - 2] = arguments[a];
          l.forEach(function (e) {
            if (!r && g(e) !== b) {
              if ("number" == typeof e) {
                if (!d.isNumber(e)) return;
              } else {
                if ("boolean" == typeof e) return o.push(e), void (n && !1 === e && (r = !0));
                if (!e) return;
              }
              o.push(e);
            }
          });
          var s = o && o.length > 0 ? o[0] : t;
          return !r && e.getValueOrDefault(s, t);
        }
      }, {
        key: "getValueOrDefault",
        value: function (e, t) {
          return g(e) === b ? t : "boolean" == typeof e ? e : "number" == typeof e ? e || 0 === e ? e : t : e || t;
        }
      }, {
        key: "keyframeWidth",
        value: function (t, n, o, r) {
          var i, l, a, s, u, c;
          return e.getFirstSet(p.width || "", null == t || null === (i = t.style) || void 0 === i ? void 0 : i.width, null === (l = e.getGroupStyle(n)) || void 0 === l || null === (l = l.keyframesStyle) || void 0 === l ? void 0 : l.width, null == o || null === (a = o.keyframesStyle) || void 0 === a ? void 0 : a.width, null == o || null === (s = o.groupsStyle) || void 0 === s || null === (s = s.keyframesStyle) || void 0 === s ? void 0 : s.width, null == r || null === (u = r.rowsStyle) || void 0 === u || null === (u = u.groupsStyle) || void 0 === u || null === (u = u.keyframesStyle) || void 0 === u ? void 0 : u.width, null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.width);
        }
      }, {
        key: "keyframeHeight",
        value: function (t, n, o, r) {
          var i, l, a, s, u, c;
          return e.getFirstSet(p.height || "", null == t || null === (i = t.style) || void 0 === i ? void 0 : i.height, null === (l = e.getGroupStyle(n)) || void 0 === l || null === (l = l.keyframesStyle) || void 0 === l ? void 0 : l.height, null == o || null === (a = o.keyframesStyle) || void 0 === a ? void 0 : a.height, null == o || null === (s = o.groupsStyle) || void 0 === s || null === (s = s.keyframesStyle) || void 0 === s ? void 0 : s.height, null == r || null === (u = r.rowsStyle) || void 0 === u || null === (u = u.groupsStyle) || void 0 === u || null === (u = u.keyframesStyle) || void 0 === u ? void 0 : u.height, null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.height);
        }
      }, {
        key: "keyframeShape",
        value: function (t, n, o, r) {
          var i,
            a,
            s,
            u,
            c,
            f,
            d = p.shape || l.Rhomb;
          return e.getFirstSet(d, null == t || null === (i = t.style) || void 0 === i ? void 0 : i.shape, null === (a = e.getGroupStyle(n)) || void 0 === a || null === (a = a.keyframesStyle) || void 0 === a ? void 0 : a.shape, null == o || null === (s = o.groupsStyle) || void 0 === s || null === (s = s.keyframesStyle) || void 0 === s ? void 0 : s.shape, null == o || null === (u = o.keyframesStyle) || void 0 === u ? void 0 : u.shape, n ? null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.groupsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.shape : void 0, null == r || null === (f = r.rowsStyle) || void 0 === f || null === (f = f.keyframesStyle) || void 0 === f ? void 0 : f.shape);
        }
      }, {
        key: "keyframeFillColor",
        value: function (t, n, o, r) {
          var i,
            l,
            a,
            s,
            u,
            c,
            f = p.fillColor || "";
          return e.getFirstSet(f, null == t || null === (i = t.style) || void 0 === i ? void 0 : i.fillColor, null === (l = e.getGroupStyle(n)) || void 0 === l || null === (l = l.keyframesStyle) || void 0 === l ? void 0 : l.fillColor, null == o || null === (a = o.groupsStyle) || void 0 === a || null === (a = a.keyframesStyle) || void 0 === a ? void 0 : a.fillColor, null == o || null === (s = o.keyframesStyle) || void 0 === s ? void 0 : s.fillColor, n ? null == r || null === (u = r.rowsStyle) || void 0 === u || null === (u = u.groupsStyle) || void 0 === u || null === (u = u.keyframesStyle) || void 0 === u ? void 0 : u.fillColor : void 0, null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.fillColor);
        }
      }, {
        key: "keyframeSelectedFillColor",
        value: function (t, n, o, r) {
          var i,
            l,
            a,
            s,
            u,
            c,
            f = p.selectedFillColor || "";
          return e.getFirstSet(f, null == t || null === (i = t.style) || void 0 === i ? void 0 : i.selectedFillColor, null === (l = e.getGroupStyle(n)) || void 0 === l || null === (l = l.keyframesStyle) || void 0 === l ? void 0 : l.selectedFillColor, null == o || null === (a = o.groupsStyle) || void 0 === a || null === (a = a.keyframesStyle) || void 0 === a ? void 0 : a.selectedFillColor, null == o || null === (s = o.keyframesStyle) || void 0 === s ? void 0 : s.selectedFillColor, n ? null == r || null === (u = r.rowsStyle) || void 0 === u || null === (u = u.groupsStyle) || void 0 === u || null === (u = u.keyframesStyle) || void 0 === u ? void 0 : u.selectedFillColor : void 0, null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.selectedFillColor);
        }
      }, {
        key: "keyframeStrokeThickness",
        value: function (t, n, o, r) {
          var i,
            l,
            a,
            s,
            u,
            c,
            f = p.strokeThickness || 0;
          return e.getFirstSet(f, null == t || null === (i = t.style) || void 0 === i ? void 0 : i.strokeThickness, null === (l = e.getGroupStyle(n)) || void 0 === l || null === (l = l.keyframesStyle) || void 0 === l ? void 0 : l.strokeThickness, null == o || null === (a = o.groupsStyle) || void 0 === a || null === (a = a.keyframesStyle) || void 0 === a ? void 0 : a.strokeThickness, null == o || null === (s = o.keyframesStyle) || void 0 === s ? void 0 : s.strokeThickness, n ? null == r || null === (u = r.rowsStyle) || void 0 === u || null === (u = u.groupsStyle) || void 0 === u || null === (u = u.keyframesStyle) || void 0 === u ? void 0 : u.strokeThickness : void 0, null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.strokeThickness);
        }
      }, {
        key: "keyframeStrokeColor",
        value: function (t, n, o, r) {
          var i,
            l,
            a,
            s,
            u,
            c,
            f = p.strokeColor || "";
          return e.getFirstSet(f, null == t || null === (i = t.style) || void 0 === i ? void 0 : i.strokeColor, null === (l = e.getGroupStyle(n)) || void 0 === l || null === (l = l.keyframesStyle) || void 0 === l ? void 0 : l.strokeColor, null == o || null === (a = o.groupsStyle) || void 0 === a || null === (a = a.keyframesStyle) || void 0 === a ? void 0 : a.strokeColor, null == o || null === (s = o.keyframesStyle) || void 0 === s ? void 0 : s.strokeColor, n ? null == r || null === (u = r.rowsStyle) || void 0 === u || null === (u = u.groupsStyle) || void 0 === u || null === (u = u.keyframesStyle) || void 0 === u ? void 0 : u.strokeColor : void 0, null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.strokeColor);
        }
      }, {
        key: "keyframeSelectedStrokeColor",
        value: function (t, n, o, r) {
          var i,
            l,
            a,
            s,
            u,
            c,
            f = p.selectedStrokeColor || "";
          return e.getFirstSet(f, null == t || null === (i = t.style) || void 0 === i ? void 0 : i.selectedStrokeColor, null === (l = e.getGroupStyle(n)) || void 0 === l || null === (l = l.keyframesStyle) || void 0 === l ? void 0 : l.selectedStrokeColor, null == o || null === (a = o.groupsStyle) || void 0 === a || null === (a = a.keyframesStyle) || void 0 === a ? void 0 : a.selectedStrokeColor, null == o || null === (s = o.keyframesStyle) || void 0 === s ? void 0 : s.selectedStrokeColor, n ? null == r || null === (u = r.rowsStyle) || void 0 === u || null === (u = u.groupsStyle) || void 0 === u || null === (u = u.keyframesStyle) || void 0 === u ? void 0 : u.selectedStrokeColor : void 0, null == r || null === (c = r.rowsStyle) || void 0 === c || null === (c = c.keyframesStyle) || void 0 === c ? void 0 : c.selectedStrokeColor);
        }
      }, {
        key: "groupHeight",
        value: function (t, n, o) {
          var r, i, l;
          return e.getFirstSet(y.height || "auto", null === (r = e.getGroupStyle(n)) || void 0 === r ? void 0 : r.height, null == o || null === (i = o.groupsStyle) || void 0 === i ? void 0 : i.height, null == t || null === (l = t.rowsStyle) || void 0 === l || null === (l = l.groupsStyle) || void 0 === l ? void 0 : l.height);
        }
      }, {
        key: "groupMarginTop",
        value: function (t, n, o) {
          var r, i, l;
          return e.getFirstSet(y.marginTop || "auto", null === (r = e.getGroupStyle(n)) || void 0 === r ? void 0 : r.marginTop, null == o || null === (i = o.groupsStyle) || void 0 === i ? void 0 : i.marginTop, null == t || null === (l = t.rowsStyle) || void 0 === l || null === (l = l.groupsStyle) || void 0 === l ? void 0 : l.marginTop);
        }
      }, {
        key: "groupFillColor",
        value: function (t, n, o) {
          var r, i, l;
          return e.getFirstSet(y.fillColor || "", null === (r = e.getGroupStyle(n)) || void 0 === r ? void 0 : r.fillColor, null == o || null === (i = o.groupsStyle) || void 0 === i ? void 0 : i.fillColor, null == t || null === (l = t.rowsStyle) || void 0 === l || null === (l = l.groupsStyle) || void 0 === l ? void 0 : l.fillColor);
        }
      }, {
        key: "groupStrokeColor",
        value: function (t, n, o) {
          var r, i, l;
          return e.getFirstSet(y.strokeColor || "", null === (r = e.getGroupStyle(n)) || void 0 === r ? void 0 : r.strokeColor, null == o || null === (i = o.groupsStyle) || void 0 === i ? void 0 : i.strokeColor, null == t || null === (l = t.rowsStyle) || void 0 === l || null === (l = l.groupsStyle) || void 0 === l ? void 0 : l.strokeColor);
        }
      }, {
        key: "groupStrokeThickness",
        value: function (t, n, o) {
          var r, i, l;
          return e.getFirstSet(y.strokeThickness || "", null === (r = e.getGroupStyle(n)) || void 0 === r ? void 0 : r.strokeThickness, null == o || null === (i = o.groupsStyle) || void 0 === i ? void 0 : i.strokeThickness, null == t || null === (l = t.rowsStyle) || void 0 === l || null === (l = l.groupsStyle) || void 0 === l ? void 0 : l.strokeThickness) || 0;
        }
      }, {
        key: "groupsRadii",
        value: function (t, n, o) {
          var r, i, l;
          return e.getFirstSet(y.radii || "", null === (r = e.getGroupStyle(n)) || void 0 === r ? void 0 : r.radii, null == o || null === (i = o.groupsStyle) || void 0 === i ? void 0 : i.radii, null == t || null === (l = t.rowsStyle) || void 0 === l || null === (l = l.groupsStyle) || void 0 === l ? void 0 : l.radii) || 0;
        }
      }, {
        key: "getRowHeight",
        value: function (t, n) {
          var o,
            r = _.height || 0;
          return e.getFirstSet(r, null == t ? void 0 : t.height, null == n || null === (o = n.rowsStyle) || void 0 === o ? void 0 : o.height);
        }
      }, {
        key: "getRowMarginBottom",
        value: function (t, n) {
          var o,
            r = _.marginBottom || 0;
          return e.getFirstSet(r, null == t ? void 0 : t.marginBottom, null == n || null === (o = n.rowsStyle) || void 0 === o ? void 0 : o.marginBottom);
        }
      }, {
        key: "getRowFillColor",
        value: function (t, n) {
          var o,
            r = _.fillColor || "";
          return e.getFirstSet(r, null == t ? void 0 : t.fillColor, null == n || null === (o = n.rowsStyle) || void 0 === o ? void 0 : o.fillColor);
        }
      }, {
        key: "headerHeight",
        value: function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 30;
          return (null == e ? void 0 : e.headerHeight) || t;
        }
      }, {
        key: "keyframeDraggable",
        value: function (t, n, o, r) {
          var i,
            l = !(arguments.length > 4 && void 0 !== arguments[4]) || arguments[4];
          return e.getValue(l, !0, null == t ? void 0 : t.draggable, null === (i = e.getGroup(n)) || void 0 === i ? void 0 : i.keyframesDraggable, null == o ? void 0 : o.keyframesDraggable, null == r ? void 0 : r.keyframesDraggable);
        }
      }, {
        key: "groupDraggable",
        value: function (t, n, o) {
          var r;
          return e.getValue(!0, !0, null === (r = e.getGroup(t)) || void 0 === r ? void 0 : r.draggable, null == n ? void 0 : n.groupsDraggable, null == o ? void 0 : o.groupsDraggable);
        }
      }], null && h(t.prototype, null), n && h(t, n), Object.defineProperty(t, "prototype", {
        writable: !1
      }), t;
      var t, n;
    }(),
    w = function (e) {
      return e.Timeline = "timeline", e.Keyframe = "keyframe", e.Group = "group", e.Row = "row", e.None = "none", e;
    }({});
  function k(e) {
    return k = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, k(e);
  }
  function C(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, P(o.key), o);
    }
  }
  function x(e, t, n) {
    return t && C(e.prototype, t), n && C(e, n), Object.defineProperty(e, "prototype", {
      writable: !1
    }), e;
  }
  function M(e, t, n) {
    return (t = P(t)) in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function P(e) {
    var t = function (e) {
      if ("object" != k(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != k(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == k(t) ? t : t + "";
  }
  var T = x(function e() {
    !function (e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }(this, e), M(this, "changed", !1), M(this, "type", w.None);
  });
  function E(e) {
    return E = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, E(e);
  }
  function D(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, O(o.key), o);
    }
  }
  function O(e) {
    var t = function (e) {
      if ("object" != E(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != E(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == E(t) ? t : t + "";
  }
  var R = function () {
    return e = function e() {
      var t, n, o;
      !function (e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }(this, e), t = this, o = !1, (n = O(n = "_prevented")) in t ? Object.defineProperty(t, n, {
        value: o,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : t[n] = o;
    }, (t = [{
      key: "preventDefault",
      value: function () {
        this._prevented = !0;
      }
    }, {
      key: "isPrevented",
      value: function () {
        return this._prevented;
      }
    }]) && D(e.prototype, t), Object.defineProperty(e, "prototype", {
      writable: !1
    }), e;
    var e, t;
  }();
  function j(e) {
    return j = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, j(e);
  }
  function N(e, t, n) {
    return t = A(t), function (e, t) {
      if (t && ("object" == j(t) || "function" == typeof t)) return t;
      if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
      return function (e) {
        if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return e;
      }(e);
    }(e, z() ? Reflect.construct(t, n || [], A(e).constructor) : t.apply(e, n));
  }
  function z() {
    try {
      var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
    } catch (e) {}
    return (z = function () {
      return !!e;
    })();
  }
  function A(e) {
    return A = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    }, A(e);
  }
  function K(e, t) {
    return K = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
      return e.__proto__ = t, e;
    }, K(e, t);
  }
  var V = function (e) {
      function t() {
        return function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, t), N(this, t, arguments);
      }
      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(e, "prototype", {
          writable: !1
        }), t && K(e, t);
      }(t, e), n = t, Object.defineProperty(n, "prototype", {
        writable: !1
      }), n;
      var n;
    }(R),
    W = function (e) {
      return e.User = "user", e.Programmatically = "programmatically", e.SetTimeMethod = "setTimeMethod", e;
    }({});
  function F(e) {
    return F = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, F(e);
  }
  function L(e, t, n) {
    return t = I(t), function (e, t) {
      if (t && ("object" == F(t) || "function" == typeof t)) return t;
      if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
      return function (e) {
        if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return e;
      }(e);
    }(e, B() ? Reflect.construct(t, n || [], I(e).constructor) : t.apply(e, n));
  }
  function B() {
    try {
      var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
    } catch (e) {}
    return (B = function () {
      return !!e;
    })();
  }
  function I(e) {
    return I = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    }, I(e);
  }
  function H(e, t) {
    return H = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
      return e.__proto__ = t, e;
    }, H(e, t);
  }
  function G(e, t, n) {
    return (t = Z(t)) in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function Z(e) {
    var t = function (e) {
      if ("object" != F(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != F(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == F(t) ? t : t + "";
  }
  var U = function (e) {
      function t() {
        var e;
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, t);
        for (var n = arguments.length, o = new Array(n), r = 0; r < n; r++) o[r] = arguments[r];
        return G(e = L(this, t, [].concat(o)), "val", 0), G(e, "prevVal", 0), G(e, "source", W.User), e;
      }
      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(e, "prototype", {
          writable: !1
        }), t && H(e, t);
      }(t, e), n = t, Object.defineProperty(n, "prototype", {
        writable: !1
      }), n;
      var n;
    }(R),
    X = function (e) {
      return e.Keyframes = "keyframes", e;
    }({});
  function Y(e) {
    return Y = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, Y(e);
  }
  function J(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, Q(o.key), o);
    }
  }
  function q(e, t, n) {
    return t && J(e.prototype, t), n && J(e, n), Object.defineProperty(e, "prototype", {
      writable: !1
    }), e;
  }
  function $(e, t, n) {
    return (t = Q(t)) in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function Q(e) {
    var t = function (e) {
      if ("object" != Y(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != Y(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == Y(t) ? t : t + "";
  }
  var ee = q(function e() {
    !function (e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }(this, e), $(this, "selected", []), $(this, "changed", []), $(this, "mode", X.Keyframes);
  });
  function te(e) {
    return te = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, te(e);
  }
  function ne(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, se(o.key), o);
    }
  }
  function oe(e, t, n) {
    return t = ie(t), function (e, t) {
      if (t && ("object" == te(t) || "function" == typeof t)) return t;
      if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
      return function (e) {
        if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return e;
      }(e);
    }(e, re() ? Reflect.construct(t, n || [], ie(e).constructor) : t.apply(e, n));
  }
  function re() {
    try {
      var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
    } catch (e) {}
    return (re = function () {
      return !!e;
    })();
  }
  function ie(e) {
    return ie = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    }, ie(e);
  }
  function le(e, t) {
    return le = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
      return e.__proto__ = t, e;
    }, le(e, t);
  }
  function ae(e, t, n) {
    return (t = se(t)) in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function se(e) {
    var t = function (e) {
      if ("object" != te(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != te(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == te(t) ? t : t + "";
  }
  var ue = function (e) {
    function t() {
      var e;
      !function (e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }(this, t);
      for (var n = arguments.length, o = new Array(n), r = 0; r < n; r++) o[r] = arguments[r];
      return ae(e = oe(this, t, [].concat(o)), "args", null), ae(e, "elements", []), ae(e, "target", null), ae(e, "point", null), e;
    }
    return function (e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), t && le(e, t);
    }(t, e), n = t, (o = [{
      key: "pos",
      get: function () {
        var e;
        return (null === (e = this.point) || void 0 === e ? void 0 : e.pos) || null;
      }
    }, {
      key: "val",
      get: function () {
        return this.point ? this.point.val : NaN;
      }
    }]) && ne(n.prototype, o), Object.defineProperty(n, "prototype", {
      writable: !1
    }), n;
    var n, o;
  }(R);
  function ce(e) {
    return ce = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, ce(e);
  }
  function fe(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, he(o.key), o);
    }
  }
  function de(e, t, n) {
    return t = ye(t), function (e, t) {
      if (t && ("object" == ce(t) || "function" == typeof t)) return t;
      if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
      return function (e) {
        if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return e;
      }(e);
    }(e, ve() ? Reflect.construct(t, n || [], ye(e).constructor) : t.apply(e, n));
  }
  function ve() {
    try {
      var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
    } catch (e) {}
    return (ve = function () {
      return !!e;
    })();
  }
  function ye(e) {
    return ye = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    }, ye(e);
  }
  function pe(e, t) {
    return pe = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
      return e.__proto__ = t, e;
    }, pe(e, t);
  }
  function _e(e, t, n) {
    return (t = he(t)) in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function he(e) {
    var t = function (e) {
      if ("object" != ce(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != ce(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == ce(t) ? t : t + "";
  }
  var me = function (e) {
      function t() {
        var e;
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, t);
        for (var n = arguments.length, o = new Array(n), r = 0; r < n; r++) o[r] = arguments[r];
        return _e(e = de(this, t, [].concat(o)), "args", null), _e(e, "elements", null), _e(e, "target", null), _e(e, "point", null), _e(e, "prevPoint", null), e;
      }
      return function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(e, "prototype", {
          writable: !1
        }), t && pe(e, t);
      }(t, e), n = t, (o = [{
        key: "pos",
        get: function () {
          var e;
          return (null === (e = this.point) || void 0 === e ? void 0 : e.pos) || null;
        }
      }]) && fe(n.prototype, o), Object.defineProperty(n, "prototype", {
        writable: !1
      }), n;
      var n, o;
    }(R),
    ge = function (e) {
      return e.Selection = "selection", e.Pan = "pan", e.NonInteractivePan = "nonInteractivePan", e.Zoom = "zoom", e.None = "none", e;
    }({}),
    be = function (e) {
      return e.Alias = "alias", e.AllScroll = "all-scroll", e.Auto = "auto", e.Cell = "cell", e.ContextMenu = "context-menu", e.ColResize = "col-resize", e.Copy = "copy", e.Crosshair = "crosshair", e.Default = "default", e.EResize = "e-resize", e.EWResize = "ew-resize", e.Grab = "grab", e.Grabbing = "grabbing", e.Help = "help", e.Move = "move", e.NResize = "n-resize", e.NEResize = "ne-resize", e.NESWResize = "nesw-resize", e.NSResize = "ns-resize", e.NWResize = "nw-resize", e.NWSEResize = "nwse-resize", e.NoDrop = "no-drop", e.None = "none", e.NotAllowed = "not-allowed", e.Pointer = "pointer", e.Progress = "progress", e.RowResize = "row-resize", e.SResize = "s-resize", e.SEResize = "se-resize", e.SWResize = "sw-resize", e.Text = "text", e.WResize = "w-resize", e.Wait = "wait", e.ZoomIn = "zoom-in", e.ZoomOut = "zoom-out", e;
    }({}),
    Se = function (e) {
      return e.None = "none", e.Triangle = "triangle", e.Rect = "rect", e;
    }({}),
    we = function (e) {
      return e.Normal = "normal", e.Append = "append", e.Revert = "revert", e;
    }({}),
    ke = function (e) {
      return e.Selected = "selected", e.TimeChanged = "timechanged", e.KeyframeChanged = "keyframeChanged", e.DragStarted = "dragStarted", e.Drag = "drag", e.DragFinished = "dragFinished", e.Scroll = "scroll", e.ScrollFinished = "scrollFinished", e.ContextMenu = "onContextMenu", e.DoubleClick = "doubleClick", e.MouseDown = "mouseDown", e;
    }({}),
    Ce = function (e) {
      return e.DefaultMode = "none", e.ZoomMode = "zoom", e.ScrollBySelection = "scrollBySelection", e;
    }({}),
    xe = {
      autoPanSpeed: 50,
      scrollByDragSpeed: .12,
      clickDetectionMs: 120,
      doubleClickTimeoutMs: 400,
      scrollFinishedTimeoutMs: 500,
      autoPanByScrollPadding: 10,
      clickThreshold: 3,
      clickDetectionMinRadius: 2,
      autoPanSpeedLimit: 10,
      defaultGroupHeight: .7
    },
    Me = {
      width: 2,
      marginTop: 15,
      marginBottom: 0,
      strokeColor: "DarkOrange",
      fillColor: "DarkOrange",
      capStyle: {
        width: 4,
        height: 10,
        capType: Se.Rect,
        strokeColor: "DarkOrange",
        fillColor: "DarkOrange"
      },
      cursor: be.EWResize
    },
    Pe = {
      snapAllKeyframesOnMove: !1,
      snapEnabled: !0,
      timelineStyle: Me,
      stepPx: 120,
      stepVal: 1e3,
      stepSmallPx: 30,
      snapStep: 200,
      leftMargin: 25,
      headerFillColor: "#101011",
      fillColor: "#101011",
      labelsColor: "#D5D5D5",
      tickColor: "#D5D5D5",
      selectionColor: "White",
      rowsStyle: _,
      keyframesStyle: p,
      groupsStyle: y,
      headerHeight: 30,
      font: "11px sans-serif",
      zoom: 1,
      zoomSpeed: .1,
      zoomMin: .1,
      zoomMax: 8,
      controlKeyIsMetaKey: !1,
      scrollContainerClass: "scroll-container",
      groupsDraggable: !0,
      keyframesDraggable: !0,
      timelineDraggable: !0,
      min: 0,
      max: Number.MAX_VALUE
    };
  function Te(e) {
    return Te = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, Te(e);
  }
  function Ee(e, t) {
    (null == t || t > e.length) && (t = e.length);
    for (var n = 0, o = Array(t); n < t; n++) o[n] = e[n];
    return o;
  }
  function De(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, Ke(o.key), o);
    }
  }
  function Oe(e, t, n) {
    return t = ze(t), function (e, t) {
      if (t && ("object" == Te(t) || "function" == typeof t)) return t;
      if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
      return function (e) {
        if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return e;
      }(e);
    }(e, Re() ? Reflect.construct(t, n || [], ze(e).constructor) : t.apply(e, n));
  }
  function Re() {
    try {
      var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
    } catch (e) {}
    return (Re = function () {
      return !!e;
    })();
  }
  function je(e, t) {
    return je = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
      return e.__proto__ = t, e;
    }, je(e, t);
  }
  function Ne() {
    return Ne = "undefined" != typeof Reflect && Reflect.get ? Reflect.get.bind() : function (e, t, n) {
      var o = function (e, t) {
        for (; !{}.hasOwnProperty.call(e, t) && null !== (e = ze(e)););
        return e;
      }(e, t);
      if (o) {
        var r = Object.getOwnPropertyDescriptor(o, t);
        return r.get ? r.get.call(arguments.length < 3 ? e : n) : r.value;
      }
    }, Ne.apply(null, arguments);
  }
  function ze(e) {
    return ze = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    }, ze(e);
  }
  function Ae(e, t, n) {
    return (t = Ke(t)) in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function Ke(e) {
    var t = function (e) {
      if ("object" != Te(e) || !e) return e;
      var t = e[Symbol.toPrimitive];
      if (void 0 !== t) {
        var n = t.call(e, "string");
        if ("object" != Te(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" == Te(t) ? t : t + "";
  }
  var Ve = function (e) {
    function t() {
      var e,
        n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null,
        o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
      return function (e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }(this, t), Ae(e = Oe(this, t), "_container", null), Ae(e, "_canvas", null), Ae(e, "_scrollContainer", null), Ae(e, "_scrollContent", null), Ae(e, "_ctx", null), Ae(e, "_startPosMouseArgs", null), Ae(e, "_scrollStartPos", null), Ae(e, "_currentPos", null), Ae(e, "_selectionRect", null), Ae(e, "_selectionRectEnabled", !1), Ae(e, "_drag", null), Ae(e, "_startedDragWithCtrl", !1), Ae(e, "_startedDragWithShiftKey", !1), Ae(e, "_scrollProgrammatically", !1), Ae(e, "_clickTimeout", null), Ae(e, "_lastClickTime", 0), Ae(e, "_lastClickPoint", null), Ae(e, "_consts", xe), Ae(e, "_clickAllowed", !1), Ae(e, "_scrollFinishedTimerRef", null), Ae(e, "_val", 0), Ae(e, "_pixelRatio", 1), Ae(e, "_currentZoom", 0), Ae(e, "_intervalRef", null), Ae(e, "_autoPanLastActionDate", 0), Ae(e, "_isPanStarted", !1), Ae(e, "_interactionMode", ge.Selection), Ae(e, "_lastUsedArgs", null), Ae(e, "_model", null), Ae(e, "_scrollAreaClickOrDragStarted", !1), Ae(e, "initialize", function (t, n) {
        if (e._model = n, !t || !t.id) throw new Error("Element cannot be empty. Should be string or DOM element.");
        e._generateContainers(t.id), e._options = d.cloneOptions(Pe), t && (e._options = e._setOptions(t)), e._subscribeComponentEvents(), e.rescale(), e.redraw();
      }), Ae(e, "_generateContainers", function (t) {
        if (t instanceof HTMLElement ? e._container = t : e._container = document.getElementById(t), !e._container) throw new Error("Element cannot be empty. Should be string or DOM element.");
        if (e._scrollContainer = document.createElement("div"), e._scrollContent = document.createElement("div"), e._canvas = document.createElement("canvas"), e._canvas && e._canvas.getContext) {
          e._container.innerHTML = "", e._container.style.position = "relative", e._canvas.style.cssText = "image-rendering: -moz-crisp-edges;image-rendering: -webkit-crisp-edges;image-rendering: pixelated;image-rendering: crisp-edges;user-select: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-o-user-select: none;user-select: none;touch-action: none;position: relative;-webkit-user-drag: none;-khtml-user-drag: none;-moz-user-drag: none;-o-user-drag: none;user-drag: none;padding: inherit", e._scrollContainer.style.cssText = "overflow: scroll;position: absolute;width:  100%;height:  100%;", e._scrollContent.style.width = e._scrollContent.style.height = "100%", e._scrollContainer.appendChild(e._scrollContent), e._container.appendChild(e._scrollContainer);
          var n = e._scrollContainer.offsetWidth - e._scrollContent.clientWidth;
          e._canvas.style.width = e._canvas.style.height = "calc(100% -" + (n || 17) + "px)", e._container.appendChild(e._canvas), e._ctx = e._getCtx();
        } else console.log("Cannot initialize canvas context.");
      }), Ae(e, "_subscribeComponentEvents", function () {
        if (e._unsubscribeComponentEvents(), !e._container || !e._scrollContainer || !e._canvas) throw Error("Cannot subscribe on scroll events while one of the containers is null or empty. Please call initialize method first");
        e._container && e._container.addEventListener("wheel", e._handleWheelEvent), e._scrollContainer && (e._scrollContainer.addEventListener("scroll", e._handleScrollEvent), e._scrollContainer.addEventListener("touchstart", e._handleScrollMouseDownEvent), e._scrollContainer.addEventListener("mousedown", e._handleScrollMouseDownEvent)), document.addEventListener("keyup", e._handleKeyUp, !1), document.addEventListener("keydown", e._handleKeyDown, !1), window.addEventListener("blur", e._handleBlurEvent, !1), window.addEventListener("resize", e._handleWindowResizeEvent, !1), e._canvas && (e._canvas.addEventListener("touchstart", e._handleMouseDownEvent, !1), e._canvas.addEventListener("mousedown", e._handleMouseDownEvent, !1), e._canvas.addEventListener("contextmenu", e._handleContextMenu, !1)), window.addEventListener("mousemove", e._handleMouseMoveEvent, !1), window.addEventListener("touchmove", e._handleMouseMoveEvent, !1), window.addEventListener("mouseup", e._handleMouseUpEvent, !1), window.addEventListener("touchend", e._handleMouseUpEvent, !1);
      }), Ae(e, "_unsubscribeComponentEvents", function () {
        var t;
        null === (t = e._container) || void 0 === t || t.removeEventListener("wheel", e._handleWheelEvent), e._scrollContainer ? (e._scrollContainer.removeEventListener("scroll", e._handleScrollEvent), e._scrollContainer.removeEventListener("touchstart", e._handleScrollMouseDownEvent), e._scrollContainer.removeEventListener("mousedown", e._handleScrollMouseDownEvent)) : console.warn("Cannot unsubscribe scroll while it's already empty"), window.removeEventListener("blur", e._handleBlurEvent), window.removeEventListener("resize", e._handleWindowResizeEvent), document.removeEventListener("keydown", e._handleKeyDown), document.removeEventListener("keyup", e._handleKeyUp), e._canvas ? (e._canvas.removeEventListener("touchstart", e._handleMouseDownEvent), e._canvas.removeEventListener("mousedown", e._handleMouseDownEvent), e._canvas.removeEventListener("contextmenu", e._handleContextMenu)) : console.warn("Cannot unsubscribe canvas while it's already empty"), window.removeEventListener("mousemove", e._handleMouseMoveEvent), window.removeEventListener("touchmove", e._handleMouseMoveEvent), window.removeEventListener("mouseup", e._handleMouseUpEvent), window.removeEventListener("touchend", e._handleMouseUpEvent);
      }), Ae(e, "dispose", function () {
        e.offAll(), e._stopAutoPan(), e._clearScrollFinishedTimer(), e._unsubscribeComponentEvents(), e._container && (e._container.innerHTML = ""), e._container = null, e._canvas = null, e._scrollContainer = null, e._scrollContent = null, e._ctx = null, e._cleanUpSelection();
      }), Ae(e, "_handleKeyUp", function (t) {
        e._interactionMode === ge.Zoom && e._setZoomCursor(t);
      }), Ae(e, "_handleKeyDown", function (t) {
        e._interactionMode === ge.Zoom && e._setZoomCursor(t);
      }), Ae(e, "_setZoomCursor", function (t) {
        e._controlKeyPressed(t) ? e._setCursor(be.ZoomOut) : e._setCursor(be.ZoomIn);
      }), Ae(e, "_handleBlurEvent", function () {
        e._cleanUpSelection(!0);
      }), Ae(e, "_handleWindowResizeEvent", function () {
        e.rescale(), e.redraw();
      }), Ae(e, "_clearScrollFinishedTimer", function () {
        e._scrollFinishedTimerRef && (clearTimeout(e._scrollFinishedTimerRef), e._scrollFinishedTimerRef = null);
      }), Ae(e, "_handleScrollMouseDownEvent", function () {
        e._scrollAreaClickOrDragStarted = !0;
      }), Ae(e, "_handleScrollEvent", function (t) {
        var n = e._scrollProgrammatically;
        e._scrollProgrammatically && (e._scrollProgrammatically = !1), e._clearScrollFinishedTimer(), e._scrollFinishedTimerRef = window.setTimeout(function () {
          e._isPanStarted || (e._clearScrollFinishedTimer(), e.rescale(), e.redraw()), e._emitScrollEvent(t, n, ke.ScrollFinished);
        }, e._consts.scrollFinishedTimeoutMs), e.redraw(), e._emitScrollEvent(t, n);
      }), Ae(e, "_controlKeyPressed", function (t) {
        return e._options && void 0 !== e._options.controlKeyIsMetaKey ? e._options.controlKeyIsMetaKey || e._options.controlKeyIsMetaKey ? t.metaKey : t.ctrlKey : t.metaKey || t.ctrlKey;
      }), Ae(e, "_handleWheelEvent", function (t) {
        if (e._scrollContainer && e._canvas) if (e._controlKeyPressed(t)) {
          t.preventDefault();
          var n = e._getMousePos(e._canvas, t),
            o = Math.max(0, n.pos.x || 0);
          e._zoom(d.sign(t.deltaY), e._options.zoomSpeed || 0, o);
        } else e.scrollTop = e._scrollContainer.scrollTop + t.deltaY, t.preventDefault();
      }), Ae(e, "_zoom", function (t, n, o) {
        if (n && n > 0 && n <= 1) {
          o += .2 * d.getDistance(e._canvasClientWidth() / 2, o);
          var r = e._canvasClientWidth() / o,
            i = e._fromScreen(o),
            l = t * e._currentZoom * n;
          e._currentZoom = e._setZoom(e._currentZoom + l);
          var a = e.valToPx(i),
            s = Math.round(a - e._canvasClientWidth() / r);
          s <= 0 && (s = 0), e._rescaleInternal(s + e._canvasClientWidth(), null, Ce.ZoomMode), e.scrollLeft = s, e.redraw();
        }
      }), Ae(e, "zoomIn", function () {
        var t,
          n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : e._options.zoomSpeed,
          o = (null === (t = e._scrollContainer) || void 0 === t ? void 0 : t.clientWidth) || 0;
        n && o && e._zoom(1, n, o / 2);
      }), Ae(e, "zoomOut", function () {
        var t,
          n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : e._options.zoomSpeed,
          o = (null === (t = e._scrollContainer) || void 0 === t ? void 0 : t.clientWidth) || 0;
        n && o && e._zoom(-1, n, o / 2);
      }), Ae(e, "_setZoom", function (t) {
        var n,
          o,
          r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null,
          i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null;
        return r = d.isNumber(r) ? r : null === (n = e._options) || void 0 === n ? void 0 : n.zoomMin, i = d.isNumber(i) ? i : null === (o = e._options) || void 0 === o ? void 0 : o.zoomMax, d.isNumber(t) ? (t = (t = d.keepInBounds(t, r, i)) || 1, e._currentZoom = t, t) : t;
      }), Ae(e, "setZoom", function (t) {
        var n = e.getZoom();
        if (n !== t) {
          var o = e._setZoom(t);
          if (n != o) return e.rescale(), e.redraw(), o;
        }
        return n;
      }), Ae(e, "getZoom", function () {
        return d.isNumber(e._currentZoom) && e._currentZoom || 1;
      }), Ae(e, "_getClickDetectionRadius", function (t) {
        var n = e._consts.clickDetectionMinRadius || 1;
        return Math.max(n, (null == t ? void 0 : t.radius) || n);
      }), Ae(e, "_handleContextMenu", function (n) {
        if (d.clearBrowserSelection(), e._canvas && e._scrollContainer) {
          var o = e._trackMousePos(e._canvas, n),
            r = e._getClickDetectionRadius(o),
            i = e.elementFromPoint(o.pos, r, []),
            l = e._findDraggableElement(i, o.val),
            a = new ue();
          a.point = o, a.args = n, a.elements = i, a.target = l, Ne(ze(t.prototype), "emit", e).call(e, ke.ContextMenu, a);
        } else e._cleanUpSelection();
      }), Ae(e, "_handleMouseDownEvent", function (n) {
        if (d.clearBrowserSelection(), e._canvas && e._scrollContainer) {
          if (e._startPosMouseArgs = e._trackMousePos(e._canvas, n), e._startPosMouseArgs) {
            var o = Date.now() - e._lastClickTime < e._consts.doubleClickTimeoutMs;
            e._lastClickPoint && e._startPosMouseArgs && d.getDistance(e._lastClickPoint.x, e._lastClickPoint.y, e._startPosMouseArgs.pos.x, e._startPosMouseArgs.pos.y) > e._consts.clickThreshold && (o = !1), e._lastClickPoint = e._startPosMouseArgs.pos, e._scrollStartPos = {
              x: e._scrollContainer.scrollLeft,
              y: e._scrollContainer.scrollTop
            }, e._clickAllowed = !0;
            var r = null;
            e._interactionMode !== ge.NonInteractivePan && e._interactionMode !== ge.None || (r = [w.Timeline]);
            var i = e._getClickDetectionRadius(e._startPosMouseArgs),
              l = e.elementFromPoint(e._startPosMouseArgs.pos, i, r),
              a = e._findDraggableElement(l, e._startPosMouseArgs.val),
              s = new ue();
            if (s.point = e._startPosMouseArgs, s.args = n, s.elements = l, s.target = a, o) Ne(ze(t.prototype), "emit", e).call(e, ke.DoubleClick, s);else if (Ne(ze(t.prototype), "emit", e).call(e, ke.MouseDown, s), e._clickTimeout = Date.now(), e._lastClickTime = Date.now(), s.isPrevented()) e._cleanUpSelection();else {
              var u;
              if (e._currentPos = e._startPosMouseArgs, a && e._interactionMode !== ge.Zoom) if (e._drag = new T(), e._drag.val = a.val, e._drag.type = a.type, e._drag.target = e._setElementDragState(a, a.val), a.type === w.Keyframe) e._startedDragWithCtrl = e._controlKeyPressed(n), e._startedDragWithShiftKey = n.shiftKey, null == a || !a.keyframe || null != a && null !== (u = a.keyframe) && void 0 !== u && u.selected || e._controlKeyPressed(n) || e._selectInternal(a.keyframe), e._drag.elements = e.getSelectedElements().map(function (t) {
                return e._setElementDragState(t, t.val);
              });else if (a.type === w.Group) {
                var c = e._drag.target.keyframes;
                c && Array.isArray(c) && (e._drag.elements = c.map(function (t) {
                  var n;
                  return e._setElementDragState(e._convertToTimelineElement((null === (n = e._drag) || void 0 === n ? void 0 : n.target.row) || null, t), t.val);
                }));
              } else e._drag.elements = [e._drag.target];
              e.redraw();
            }
          }
        } else e._cleanUpSelection();
      }), Ae(e, "_setElementDragState", function (e, t) {
        var n = e;
        return n.prevVal = n.val, void 0 !== n.startedVal && null !== n.startedVal || (n.startedVal = t), void 0 !== n.prevVal && null !== n.prevVal || (n.prevVal = t), n.val = t, n;
      }), Ae(e, "isLeftButtonClicked", function (e) {
        return !!e && 1 == e.buttons;
      }), Ae(e, "_handleMouseMoveEvent", function (t) {
        if (t ? e._lastUsedArgs = t : t = e._lastUsedArgs, t && e._canvas) {
          var n = t,
            o = n.changedTouches && n.changedTouches.length > 0;
          e._currentPos = e._trackMousePos(e._canvas, t), !e._isPanStarted && e._selectionRect && e._clickTimeoutIsOver() ? e._interactionMode === ge.None || e._interactionMode === ge.Zoom || e._interactionMode === ge.NonInteractivePan ? e._selectionRectEnabled = !1 : e._selectionRectEnabled = !0 : e._selectionRectEnabled = !1;
          var r = e.isLeftButtonClicked(t);
          if (r || (e._scrollAreaClickOrDragStarted = !1), e._startPosMouseArgs) {
            if (r || o) {
              if (e._drag && !e._startedDragWithCtrl) {
                var i = e._currentPos.val;
                if (e._drag.type === w.Timeline) e._setTimeInternal(i, W.User);else if ((e._drag.type == w.Keyframe || e._drag.type == w.Group) && e._drag.elements) {
                  var l = Math.floor(i - e._drag.val);
                  if (0 !== e._moveElements(l, e._drag.elements, W.User)) {
                    if (!e._drag.changed) {
                      e._drag.prevVal = e._drag.val;
                      var a = e._emitDragStartedEvent(e._drag);
                      if (!a || a.isPrevented()) return e._cleanUpSelection(!0), void (e._drag = null);
                    }
                    e._drag.changed = !0, e._drag.val += l, e._emitDragEvent(e._drag);
                  }
                }
              }
              e._interactionMode !== ge.Pan && e._interactionMode !== ge.NonInteractivePan || e._drag ? e._interactionMode !== ge.None && e._scrollBySelectionOutOfBounds(e._currentPos.pos) : (e._isPanStarted = !0, e._setCursor(be.Grabbing), e._scrollByPan(e._startPosMouseArgs.pos, e._currentPos.pos, e._scrollStartPos)), e.redraw();
            } else e._cleanUpSelection(!0), e.redraw();
          } else if (!o) {
            var s = null;
            e._interactionMode !== ge.NonInteractivePan && e._interactionMode !== ge.None || (s = [w.Timeline]);
            var u = e._getClickDetectionRadius(e._currentPos),
              c = e.elementFromPoint(e._currentPos.pos, u, s),
              f = e._findDraggableElement(c, e._currentPos.val);
            if (e._isPanStarted || e._interactionMode === ge.Pan || e._interactionMode === ge.NonInteractivePan) r ? e._setCursor(be.Grabbing) : e._setCursor(be.Grab);else {
              if (e._interactionMode === ge.Zoom) return void e._setZoomCursor(t);
              e._setCursor(be.Default);
            }
            if (f) {
              var d = null;
              if (f.type === w.Group) d = d || be.EWResize;else if (f.type == w.Keyframe) d = d || be.Pointer;else if (f.type == w.Timeline) {
                var v;
                d = d || (null === (v = e._options) || void 0 === v || null === (v = v.timelineStyle) || void 0 === v ? void 0 : v.cursor) || null;
              }
              d && e._setCursor(d);
            }
          }
          o && t.preventDefault();
        }
      }), Ae(e, "_handleMouseUpEvent", function (t) {
        if (e._scrollAreaClickOrDragStarted = !1, e._canvas && e._startPosMouseArgs) {
          var n = e._trackMousePos(e._canvas, t);
          if (e._clickAllowed || !e._clickTimeoutIsOver() || e._drag && (e._startedDragWithCtrl || e._startedDragWithShiftKey)) {
            if (e._options && e._interactionMode === ge.Zoom) {
              var o = e._controlKeyPressed(t) ? 1 : -1,
                r = e._getMousePos(e._canvas, t),
                i = Math.max(0, r.pos.x || 0);
              e._zoom(o, e._options.zoomSpeed || 0, i);
            } else e._performClick(n, e._drag);
          } else if (!e._drag && e._selectionRect && e._selectionRectEnabled) if (e._interactionMode === ge.Zoom) ;else if (e._interactionMode !== ge.None) {
            var l = e._getKeyframesByRectangle(e._selectionRect),
              a = t.shiftKey ? we.Append : we.Normal;
            e.select(l, a);
          }
          e._cleanUpSelection(), e.redraw();
        }
      }), Ae(e, "_canvasClientHeight", function () {
        return e._canvas ? e._canvas.clientHeight : 0;
      }), Ae(e, "_canvasClientWidth", function () {
        return e._canvas ? e._canvas.clientWidth : 0;
      }), Ae(e, "_getKeyframesByRectangle", function (t) {
        var n = [];
        return e._forEachKeyframe(function (e) {
          (e.shape === l.Rect ? d.isRectIntersects(e.size, t) : d.isOverlap(e.size.x, e.size.y, t)) && n.push(e.model);
        }), n;
      }), Ae(e, "_performClick", function (t, n) {
        var o = !1;
        if (n && n.type === w.Keyframe) {
          var r,
            i,
            l = we.Normal;
          e._startedDragWithCtrl && e._controlKeyPressed(t.args) ? e._controlKeyPressed(t.args) && (l = we.Revert) : e._startedDragWithShiftKey && t.args.shiftKey && (l = we.Append), o = e._selectInternal((null == n || null === (r = n.target) || void 0 === r ? void 0 : r.keyframe) || null, l).selectionChanged || o, t.args.shiftKey && !1 !== (null === (i = e._options) || void 0 === i ? void 0 : i.timelineDraggable) && (o = e._setTimeInternal(t.val, W.User) || o);
        } else {
          var a;
          o = e._selectInternal(null).selectionChanged || o, !1 !== (null === (a = e._options) || void 0 === a ? void 0 : a.timelineDraggable) && (o = e._setTimeInternal(t.val, W.User) || o);
        }
        return o;
      }), Ae(e, "_setKeyframePos", function (t, n) {
        var o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : W.Programmatically;
        if (!t || !t.keyframe) return n;
        if (n = Math.floor(n), t.keyframe && t.keyframe.val != n) {
          t.prevVal = t.val, t.val = n, t.keyframe.val = n;
          var r = e._emitKeyframeChanged(t, o);
          return r.isPrevented() && (t.val = r.prevVal, t.keyframe.val = r.prevVal), n;
        }
        return n;
      }), Ae(e, "_setCursor", function (t) {
        e._canvas && e._canvas.style.cursor != t && (e._canvas.style.cursor = t);
      }), Ae(e, "setInteractionMode", function (t) {
        e._interactionMode != t && (e._interactionMode = t, e._cleanUpSelection(!0), e.redraw());
      }), Ae(e, "getInteractionMode", function () {
        return e._interactionMode;
      }), Ae(e, "_convertToTimelineElement", function (e, t) {
        return {
          type: w.Keyframe,
          val: t.val,
          keyframe: t,
          row: e
        };
      }), Ae(e, "getSelectedKeyframes", function () {
        return e.getSelectedElements().map(function (e) {
          return e.keyframe;
        });
      }), Ae(e, "getSelectedElements", function () {
        var t = [];
        return e._forEachKeyframe(function (n) {
          n && n.model.selected && t.push(e._convertToTimelineElement(n.rowViewModel.model, n.model));
        }), t;
      }), Ae(e, "getAllKeyframes", function () {
        var t = [];
        return e._forEachKeyframe(function (e) {
          t.push(e.model);
        }), t;
      }), Ae(e, "selectAllKeyframes", function () {
        return e.select(e.getAllKeyframes(), we.Normal);
      }), Ae(e, "deselectAll", function () {
        return e.select(null);
      }), Ae(e, "_changeNodeState", function (e, t, n) {
        if (t.selected !== n) {
          var o = "boolean" != typeof t.selectable || t.selectable;
          if (!n || n && o) return t.selected = n, e.changed.push(t), !0;
        }
        return !1;
      }), Ae(e, "select", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : we.Normal,
          o = e._selectInternal(t, n);
        return o.selectionChanged && e.redraw(), o;
      }), Ae(e, "_selectInternal", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : we.Normal;
        t || (t = []), Array.isArray(t) || (t = [t]);
        var o = {
            selectionChanged: !1,
            selected: e.getSelectedKeyframes(),
            changed: []
          },
          r = t;
        if (r && n === we.Append) t.forEach(function (t) {
          e._changeNodeState(o, t, !0) && t.selected && o.selected.push(t);
        });else if (r && n === we.Revert) t.forEach(function (t) {
          o.selected.indexOf(t) >= 0 ? (e._changeNodeState(o, t, !1), d.deleteElement(o.selected, t)) : (e._changeNodeState(o, t, !0), t.selected && o.selected.push(t));
        });else if (n === we.Normal) {
          var i = [];
          t && t.forEach(function (t) {
            e._changeNodeState(o, t, !0), t.selected && i.push(t);
          }), o.selected.forEach(function (t) {
            r.indexOf(t) >= 0 || e._changeNodeState(o, t, !1);
          }), o.changed.length > 0 && (i ? o.selected = i : o.selected.length = 0);
        }
        return o.changed.length > 0 && (o.selectionChanged = !0, e._emitKeyframesSelected(o)), o;
      }), Ae(e, "_startAutoPan", function () {
        e._consts.autoPanSpeed && (e._intervalRef || (e._intervalRef = window.setInterval(function () {
          e._handleMouseMoveEvent(null);
        }, e._consts.autoPanSpeed)));
      }), Ae(e, "_stopAutoPan", function () {
        e._intervalRef && (clearInterval(e._intervalRef), e._intervalRef = null), e._autoPanLastActionDate = 0;
      }), Ae(e, "_toScreenPx", function (t) {
        return e.valToPx(t) - e.scrollLeft + e._leftMargin();
      }), Ae(e, "_fromScreen", function (t) {
        return e.pxToVal(e.scrollLeft + t - e._leftMargin());
      }), Ae(e, "valToPx", function (t) {
        if (!e._options || !e._options.stepPx) return t;
        var n = e._options.min || 0;
        d.isNumber(n) || (n = 0), n *= e._currentZoom || 1;
        var o = (e._options.stepVal || 0) * e._currentZoom || 1;
        return (-n + t) * (e._options.stepPx / o);
      }), Ae(e, "_mousePosToVal", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
          o = Math.min(t, e._canvasClientWidth()),
          r = e._fromScreen(o);
        return r = Math.round(r), n && (r = e.snapVal(r)), r;
      }), Ae(e, "_formatUnitsText", function (e) {
        var t = d.sign(e) < 0 ? "-" : "",
          n = (e = Math.abs(e)) / 1e3,
          o = Math.floor(n / 31536e3);
        n %= 31536e3;
        var r = Math.floor(n / 86400);
        n %= 86400;
        var i = Math.floor(n / 3600);
        n %= 3600;
        var l = Math.floor(n / 60);
        n %= 60;
        var a = "";
        return o && (a += o + ":"), r && (a += r + ":"), i && (a += i + ":"), l && (a += i ? d.timePadZero(l) : l + ":"), isNaN(n) || (a += l ? d.timePadZero(n) : n), t + a;
      }), Ae(e, "_leftMargin", function () {
        var t;
        return (null === (t = e._options) || void 0 === t ? void 0 : t.leftMargin) || 0;
      }), Ae(e, "_renderTicks", function () {
        var t;
        if (!(!e._ctx || !e._ctx.canvas || e._ctx.canvas.clientWidth <= 0 || e._ctx.canvas.clientHeight <= 0) && e._options && e._options.stepPx) {
          var n = e._canvasClientWidth() - e._leftMargin(),
            o = e.pxToVal(e.scrollLeft),
            r = e.pxToVal(e.scrollLeft + n);
          if (!isNaN(o) && !isNaN(r) && o !== r) {
            if (r < o) {
              var i = r;
              r = o, o = i;
            }
            var l = d.getDistance(o, r);
            if (!(l <= 0)) {
              var a = d.findGoodStep(l / (n / e._options.stepPx), 0, null === (t = e._options) || void 0 === t ? void 0 : t.denominators),
                s = Math.floor(o / a) * a,
                u = Math.ceil(r / a) * a + a;
              if (d.isNumber(a) && !(a <= 0) && 0 !== Math.abs(u - s)) {
                var c,
                  f = 0;
                e._options.stepSmallPx && (f = d.findGoodStep(l / (n / e._options.stepSmallPx), 0, null === (c = e._options) || void 0 === c ? void 0 : c.denominators));
                var v = 0;
                e._ctx.save();
                for (var y = S.headerHeight(e._options), p = y / 2, _ = y / 1.3, h = s; h <= u; h += a) {
                  var m = e._getSharp(e._toScreenPx(h));
                  e._ctx.save(), e._ctx.beginPath(), e._ctx.setLineDash([4]), e._ctx.lineWidth = 1, e._options.tickColor && (e._ctx.strokeStyle = e._options.tickColor), d.drawLine(e._ctx, m, p, m, y), e._ctx.stroke(), e._options.labelsColor && (e._ctx.fillStyle = e._options.labelsColor), e._options.font && (e._ctx.font = e._options.font);
                  var g = e._formatUnitsText(h),
                    b = e._ctx.measureText(g),
                    w = m - b.width / 2;
                  if ((isNaN(v) || v <= w) && (v = w + b.width, e._ctx.fillText(g, w, 10)), e._ctx.restore(), d.isNumber(f) && !(f <= 0)) for (var k = h + f; k < h + a; k += f) {
                    var C = e._getSharp(e._toScreenPx(k));
                    e._ctx.beginPath(), e._ctx.lineWidth = e._pixelRatio, e._options.tickColor && (e._ctx.strokeStyle = e._options.tickColor), d.drawLine(e._ctx, C, _, C, y), e._ctx.stroke();
                  }
                }
                e._ctx.restore();
              }
            }
          }
        }
      }), Ae(e, "_generateViewModel", function () {
        var t = {
          size: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          },
          min: null,
          max: null,
          rowsViewModels: [],
          keyframesViewModels: []
        };
        if (!e._model) return t;
        var n = e._model.rows;
        if (!n || !Array.isArray(n) || n.length <= 0) return t;
        var o = S.headerHeight(e._options);
        return n.forEach(function (n, r) {
          if (n && !n.hidden) {
            var i = S.getRowHeight(n.style || null, e._options),
              l = S.getRowMarginBottom(n.style || null, e._options),
              a = o - (e._scrollContainer ? e._scrollContainer.scrollTop : 0);
            o += i + l, 0 == r && (t.size.y = a), t.size.height = Math.max(o + i, t.size.height);
            var s = {
              size: {
                x: 0,
                y: a,
                width: e._canvasClientWidth(),
                height: i
              },
              marginBottom: l,
              model: n,
              index: r,
              min: null,
              max: null,
              groupsViewModels: [],
              keyframesViewModels: []
            };
            t.rowsViewModels.push(s), !n.keyframes || !n.keyframes.forEach || n.keyframes.length <= 0 || (n && n.keyframes && n.keyframes.forEach(function (o) {
              var r;
              if (o && d.isNumber(o.val)) {
                if (!o.hidden) {
                  var i = (null === (r = s.groupsViewModels) || void 0 === r ? void 0 : r.find(function (e) {
                    return o.group === e.groupModel;
                  })) || null;
                  i || (i = {
                    min: o.val,
                    max: o.val,
                    size: null,
                    groupModel: o.group,
                    keyframesViewModels: []
                  }, s.groupsViewModels.push(i));
                  var l = S.keyframeShape(o, o.group, n.style || null, e._options),
                    a = e._getKeyframePosition(o, i, s, l),
                    u = {
                      model: o,
                      rowViewModel: s,
                      groupViewModel: i,
                      size: a,
                      shape: l
                    },
                    c = null === i.min ? o.val : Math.min(o.val, i.min),
                    f = null === i.max ? o.val : Math.max(o.val, i.max);
                  d.isNumber(c) && (i.min = c), d.isNumber(f) && (i.max = f), s.keyframesViewModels.push(u), i.keyframesViewModels.push(u), t.keyframesViewModels.push(u);
                }
              } else console.log("Unexpected null keyframe or having invalid value");
            }), s.groupsViewModels.forEach(function (t) {
              d.setMinMax(s, t, !0);
              var n = e._getKeyframesGroupSize(t, s);
              t.size = n;
            }), d.setMinMax(t, s, !0));
          }
        }), d.isNumber(t.max) && (t.max || 0 === t.max) && (t.size.width = e.valToPx(t.max)), t;
      }), Ae(e, "_renderRows", function () {
        if (e._ctx) {
          var t = e._generateViewModel();
          if (null != t && t.rowsViewModels) try {
            e._ctx.save(), t.rowsViewModels.forEach(function (t) {
              if (t && e._ctx) {
                e._ctx.fillStyle = S.getRowFillColor(t.model.style || null, e._options);
                var n = e._cutBounds(t.size);
                if (null != n && n.rect) {
                  var o = null == n ? void 0 : n.rect;
                  e._ctx.fillRect(o.x, o.y, o.width, o.height);
                }
                e._renderGroupBounds(t);
              }
            });
          } finally {
            e._ctx.restore();
          }
        }
      }), Ae(e, "_renderGroupBounds", function (t) {
        var n;
        t && e._ctx && (null == t || null === (n = t.groupsViewModels) || void 0 === n || n.forEach(function (n) {
          var o, r, i, l, a;
          if (e._ctx && !(((null == n || null === (o = n.keyframesViewModels) || void 0 === o ? void 0 : o.length) || 0) <= 1)) {
            var s = S.groupFillColor(e._options, n.groupModel, null == t || null === (r = t.model) || void 0 === r ? void 0 : r.style),
              u = S.groupStrokeColor(e._options, n.groupModel, null == t || null === (i = t.model) || void 0 === i ? void 0 : i.style),
              c = S.groupStrokeThickness(e._options, n.groupModel, null == t || null === (l = t.model) || void 0 === l ? void 0 : l.style),
              f = S.groupsRadii(e._options, n.groupModel, null == t || null === (a = t.model) || void 0 === a ? void 0 : a.style);
            if (n.size) try {
              e._ctx.save();
              var d = e._cutBounds(n.size);
              if (null != d && d.rect) {
                var v = d.rect;
                u || (c = 0), e._ctx.strokeStyle = c > 0 ? u : "", e._ctx.fillStyle = s, e._ctx.lineWidth = c, e._ctx.beginPath(), e._ctx.roundRect(v.x + c, v.y + c, v.width - c, v.height - c, f), e._ctx.fill(), c > 0 && e._ctx.stroke();
              }
            } finally {
              e._ctx.restore();
            } else console.log("Size of the group cannot be calculated");
          }
        }));
      }), Ae(e, "_cutBounds", function (t) {
        if (!t) return null;
        var n = e._canvasClientWidth() - 0,
          o = S.headerHeight(e._options) + 0,
          r = e._canvasClientHeight() - 0;
        return e._cutBoundsWhenOverlap(t, 0, n, o, r);
      }), Ae(e, "_cutBoundsWhenOverlap", function (e, t, n, o, r) {
        if (!e) return null;
        if (d.isRectIntersects(e, {
          x: t,
          y: o,
          width: d.getDistance(t, n),
          height: d.getDistance(o, r)
        })) {
          var i = Math.max(e.y, o),
            l = Math.max(e.x, t),
            a = e.x - l,
            s = e.y - i;
          return {
            rect: {
              height: e.height + s,
              width: e.width + a,
              x: l,
              y: i
            },
            overlapY: Math.abs(s) > 0,
            overlapX: Math.abs(a) > 0
          };
        }
        return null;
      }), Ae(e, "_getKeyframesGroupSize", function (t, n) {
        var o,
          r,
          i = n.size.y,
          l = n.size.height,
          a = t.groupModel || null,
          s = S.groupHeight(e._options, a, null == n || null === (o = n.model) || void 0 === o ? void 0 : o.style),
          u = S.groupMarginTop(e._options, a, null == n || null === (r = n.model) || void 0 === r ? void 0 : r.style),
          c = "auto" === s;
        s && !c || (s = Math.floor(l)), (s = "string" == typeof s ? parseInt(s) : s) > l && (s = l);
        var f = "auto" === u;
        "string" == typeof u && (u = f ? (l - s) / 2 : parseInt(u) || 0), f || c && (s -= 2 * u);
        var v = e._toScreenPx(t.min),
          y = e._toScreenPx(t.max);
        return {
          x: v,
          y: i + u,
          height: s,
          width: d.getDistance(v, y)
        };
      }), Ae(e, "_getKeyframePosition", function (t, n, o, r) {
        var i, a;
        if (!t) return console.log("keyframe should be defined."), null;
        var s = t.val;
        if (!d.isNumber(s)) return null;
        var u = o.size,
          c = u.y + u.height / 2,
          f = (null == n ? void 0 : n.groupModel) || null,
          v = S.keyframeHeight(t, f, null == o || null === (i = o.model) || void 0 === i ? void 0 : i.style, e._options),
          y = S.keyframeWidth(t, f, null == o || null === (a = o.model) || void 0 === a ? void 0 : a.style, e._options);
        if ("auto" === v && (v = u.height / 3), "auto" === y && (y = v), v = Number(v), !Number.isNaN(v) && v && v > 0) {
          var p = {
            x: Math.floor(e._toScreenPx(s)),
            y: c = Math.floor(c),
            height: v,
            width: y
          };
          return r === l.Rect && (p.y = p.y - p.height / 2, p.x = p.x - p.width / 2), p;
        }
        return null;
      }), Ae(e, "_renderKeyframes", function () {
        e._forEachKeyframe(function (t) {
          if (e._ctx) {
            var n = t.size;
            if (n) {
              var o = e._getSharp(n.x),
                r = n.y,
                i = e._cutBounds({
                  x: o - n.width / 2,
                  y: r - n.height / 2,
                  width: n.width,
                  height: n.height
                });
              if (!i) return;
              e._ctx.save();
              try {
                i && i.overlapY && (e._ctx.beginPath(), e._ctx.rect(0, S.headerHeight(e._options), e._canvasClientWidth(), e._canvasClientWidth()), e._ctx.clip()), e._renderKeyframe(e._ctx, t);
              } finally {
                e._ctx.restore();
              }
            }
          }
        });
      }), Ae(e, "_renderKeyframe", function (t, n) {
        var o,
          r = n.shape;
        if (r !== l.None) {
          var i = n.size,
            a = e._getSharp(i.x),
            s = i.y,
            u = n.model,
            c = n.rowViewModel.model.style || null,
            f = (null == n || null === (o = n.groupViewModel) || void 0 === o ? void 0 : o.groupModel) || null,
            d = u.selected ? S.keyframeSelectedFillColor(u, f, c, e._options) : S.keyframeFillColor(u, f, c, e._options),
            v = S.keyframeStrokeThickness(u, f, c, e._options),
            y = "";
          v > 0 && (y = u.selected ? S.keyframeSelectedStrokeColor(u, f, c, e._options) : S.keyframeStrokeColor(u, f, c, e._options)), r == l.Rhomb ? (t.beginPath(), t.translate(a, s), t.rotate(45 * Math.PI / 180), v > 0 && y && (t.fillStyle = y, t.rect(-i.width / 2, -i.height / 2, i.width, i.height), t.fill()), t.fillStyle = d, t.translate(v, v), t.rect(-i.width / 2, -i.height / 2, i.width - 2 * v, i.height - 2 * v), t.fill()) : r == l.Circle ? (t.beginPath(), v > 0 && y && (t.fillStyle = y, t.arc(a, s, i.height, 0, 2 * Math.PI)), t.fillStyle = d, t.arc(a, s, i.height - v, 0, 2 * Math.PI), t.fill()) : r == l.Rect && (t.beginPath(), v > 0 && y && (t.fillStyle = y, t.rect(a, s, i.width, i.height), t.fill()), t.fillStyle = d, t.rect(a + v, s + v, i.width - v, i.height - v), t.fill());
        }
      }), Ae(e, "_renderSelectionRect", function () {
        if (!e._drag && e._ctx && e._canvas) {
          if (e._ctx.save(), e._selectionRect && e._selectionRectEnabled) {
            e._ctx.setLineDash([4]), e._ctx.lineWidth = e._pixelRatio;
            var t = e._options.selectionColor;
            t && (e._ctx.strokeStyle = t), e._ctx.strokeRect(e._getSharp(e._selectionRect.x, 1), e._getSharp(e._selectionRect.y, 1), Math.floor(e._selectionRect.width), Math.floor(e._selectionRect.height));
          }
          e._ctx.restore();
        }
      }), Ae(e, "_renderBackground", function () {
        e._ctx && e._canvas && (e._options.fillColor ? (e._ctx.save(), e._ctx.beginPath(), e._ctx.rect(0, 0, e._canvasClientWidth(), e._canvasClientHeight()), e._ctx.fillStyle = e._options.fillColor, e._ctx.fill(), e._ctx.restore()) : e._ctx.clearRect(0, 0, e._canvas.width, e._canvas.height));
      }), Ae(e, "_renderTimeline", function () {
        if (e._ctx && e._options && e._options.timelineStyle) {
          var t = e._options.timelineStyle;
          e._ctx.save();
          try {
            var n = t.width || 1;
            e._ctx.lineWidth = n * e._pixelRatio;
            var o = e._getSharp(e._toScreenPx(e._val), n);
            t.strokeColor && (e._ctx.strokeStyle = t.strokeColor), t.fillColor && (e._ctx.fillStyle = t.fillColor);
            var r = t.marginTop || 0,
              i = t.marginBottom || 0;
            e._ctx.beginPath();
            var l = e._canvasClientHeight() - i;
            d.drawLine(e._ctx, o, r, o, l), e._ctx.stroke(), e._renderTimelineCap(o, r);
          } finally {
            e._ctx.restore();
          }
        }
      }), Ae(e, "_renderTimelineCap", function (t, n) {
        var o,
          r = null === (o = e._options) || void 0 === o || null === (o = o.timelineStyle) || void 0 === o ? void 0 : o.capStyle;
        if (e._ctx && r && r.capType !== Se.None) {
          e._ctx.save();
          try {
            var i = r.width || 0,
              l = r.height || 0;
            i && l && (e._ctx.strokeStyle = r.strokeColor || "", e._ctx.fillStyle = r.fillColor || "white", r.capType === Se.Triangle ? (e._ctx.beginPath(), e._ctx.moveTo(t - i / 2, n), e._ctx.lineTo(t + i / 2, n), e._ctx.lineTo(t, l), e._ctx.closePath(), e._ctx.stroke()) : r.capType === Se.Rect && (e._ctx.fillRect(t - i / 2, n, i, l), e._ctx.fill()));
          } finally {
            e._ctx.restore();
          }
        }
      }), Ae(e, "_renderHeaderBackground", function () {
        e._ctx && e._options && S.headerHeight(e._options) && (e._ctx.save(), e._ctx.lineWidth = e._pixelRatio, e._options.headerFillColor ? (e._ctx.lineWidth = e._pixelRatio, e._ctx.fillStyle = e._options.headerFillColor, e._ctx.fillRect(0, 0, e._canvasClientWidth(), S.headerHeight(e._options))) : e._ctx.clearRect(0, 0, e._canvasClientWidth(), S.headerHeight(e._options)), e._ctx.restore());
      }), Ae(e, "redraw", function () {
        var t;
        null !== (t = window) && void 0 !== t && t.requestAnimationFrame ? window.requestAnimationFrame(e._redrawInternal) : e._redrawInternal();
      }), Ae(e, "scrollToRightBounds", function () {
        e._scrollContainer && e._scrollContainer.scrollLeft !== e._scrollContainer.scrollWidth && (e.scrollLeft = e._scrollContainer.scrollWidth);
      }), Ae(e, "_redrawInternal", function () {
        e._ctx && e._scrollContainer ? (e.valToPx(e._val) > e._scrollContainer.scrollWidth && (e.rescale(), !e._isPanStarted && e._drag && e._drag.type !== w.Timeline && e.scrollToRightBounds()), e._renderBackground(), e._renderRows(), e._renderHeaderBackground(), e._renderTicks(), e._renderKeyframes(), e._renderSelectionRect(), e._renderTimeline()) : console.log("Context is not initialized");
      }), Ae(e, "_getSharp", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1;
        return t = Math.round(t), n % 2 == 0 ? t : t + e._pixelRatio / 2;
      }), Ae(e, "getTime", function () {
        return e._val;
      }), Ae(e, "_setTimeInternal", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : W.Programmatically;
        if (!e._options) return !1;
        if (t = Math.round(t), t = d.keepInBounds(t, e._options.min), e._val != t) {
          var o = e._val,
            r = new U();
          return r.val = t, r.prevVal = o, r.source = n, e._val = t, e.emit(ke.TimeChanged, r), !r.isPrevented() || (e._val = o, !1);
        }
        return !1;
      }), Ae(e, "setTime", function (t) {
        if (e._drag && e._drag.type === w.Timeline) return !1;
        var n = e._setTimeInternal(t, W.SetTimeMethod);
        return n && (e.rescale(), e.redraw()), n;
      }), Ae(e, "getOptions", function () {
        return e._options;
      }), Ae(e, "setOptions", function (t) {
        return e._options = e._setOptions(t), e.rescale(), e.redraw(), e._options;
      }), Ae(e, "_applyContainersStyles", function () {
        if (e._scrollContainer && e._options) {
          var t = e._scrollContainer.classList;
          e._options.scrollContainerClass && !t.contains(e._options.scrollContainerClass) && t.add(e._options.scrollContainerClass), e._options.fillColor && (e._scrollContainer.style.background = e._options.fillColor);
        }
      }), Ae(e, "_setOptions", function (t) {
        return t ? (e._options = d.mergeOptions(e._options, t), e._options.snapStep = d.keepInBounds(e._options.snapStep || 0, 0, e._options.stepVal || 0), e._currentZoom = e._setZoom(e._options.zoom || 0, e._options.zoomMin, e._options.zoomMax), e._options.min = d.isNumber(e._options.min) ? e._options.min : 0, e._options.max = d.isNumber(e._options.max) ? e._options.max : Number.MAX_VALUE, e._applyContainersStyles(), !1 === t.timelineDraggable && e._drag && e._drag.type === w.Timeline && e._cleanUpSelection(), e._options) : e._options || {};
      }), Ae(e, "getModel", function () {
        return e._model;
      }), Ae(e, "setModel", function (t) {
        e._model = t, e.rescale(), e.redraw();
      }), Ae(e, "_getMousePos", function (t, n) {
        var o = 1,
          r = 0,
          i = 0;
        if (n.changedTouches && n.changedTouches.length > 0) {
          var l = n.changedTouches[0];
          r = l.clientX, i = l.clientY, o = Math.max(o, l.radiusX, l.radiusY);
        } else r = n.clientX, i = n.clientY;
        var a = t.getBoundingClientRect(),
          s = t.width / e._pixelRatio / a.width,
          u = t.height / e._pixelRatio / a.height;
        return {
          pos: {
            x: (r - a.left) * s,
            y: (i - a.top) * u
          },
          radius: o,
          args: n
        };
      }), Ae(e, "_updateCanvasScale", function () {
        if (!e._scrollContainer || !e._container || !e._ctx) return console.log("Component should be initialized first."), !1;
        var t = !1,
          n = e._scrollContainer.clientWidth * e._pixelRatio,
          o = e._scrollContainer.clientHeight * e._pixelRatio;
        return Math.floor(n) != Math.floor(e._ctx.canvas.width) && (e._ctx.canvas.width = n, t = !0), Math.floor(o) != Math.floor(e._ctx.canvas.height) && (e._ctx.canvas.height = o, t = !0), t && e._ctx.setTransform(e._pixelRatio, 0, 0, e._pixelRatio, 0, 0), t;
      }), Ae(e, "rescale", function () {
        return e._rescaleInternal();
      }), Ae(e, "_rescaleInternal", function () {
        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null,
          n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null,
          o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : Ce.DefaultMode,
          r = e._updateCanvasScale();
        if (!e._scrollContent) return r;
        var i = e._generateViewModel();
        if (i && i.size) {
          var l,
            a = e._options.stepPx || 0;
          t = t || 0;
          var s = e.valToPx(e._val) + e._leftMargin(),
            u = 0,
            c = e.scrollLeft + e.getClientWidth();
          s >= c && (u = o == Ce.ScrollBySelection ? Math.floor(s + e._leftMargin()) : Math.floor(s + e.getClientWidth() + e._leftMargin()));
          var f = i.size.width + e._leftMargin() + a;
          t = Math.max(t, f, c, u);
          var d = Math.floor(t) + "px";
          d != e._scrollContent.style.minWidth && (e._scrollContent.style.minWidth = d, r = !0), n = Math.max(Math.floor(i.size.height + .2 * e._canvasClientHeight()), ((null === (l = e._scrollContainer) || void 0 === l ? void 0 : l.scrollTop) || 0) + e._canvasClientHeight() - 1, Math.round(n || 0));
          var v = Math.floor(n) + "px";
          if (e._scrollContent.style.minHeight != v) return e._scrollContent.style.minHeight = v, r;
        }
        return r;
      }), Ae(e, "_filterDraggableElements", function (t) {
        return t.filter(function (t) {
          if (!t) return !1;
          var n;
          if (t.type === w.Keyframe) {
            if (!S.keyframeDraggable(t.keyframe || null, (null === (n = t.keyframe) || void 0 === n ? void 0 : n.group) || null, (null == t ? void 0 : t.row) || null, e._options)) return !1;
          } else if (t.type === w.Group) {
            if (!S.groupDraggable(t.group || null, t.row || null, e._options)) return !1;
          } else if (t.type === w.Timeline) {
            var o;
            if (!1 === (null === (o = e._options) || void 0 === o ? void 0 : o.timelineDraggable)) return !1;
          } else if (t.type === w.Row) return !1;
          return !0;
        });
      }), Ae(e, "_findDraggableElement", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null,
          o = function (e) {
            return e === w.Timeline ? 1 : e === w.Keyframe ? 2 : e === w.Group ? 3 : -1;
          },
          r = e._filterDraggableElements(t).sort(function (e, t) {
            var r = o(e.type),
              i = o(t.type);
            return r !== i || null !== n && (r = d.getDistance(e.val, n)) !== (i = d.getDistance(t.val, n)) ? r < i ? 1 : -1 : 0;
          });
        return r.length > 0 ? r[r.length - 1] : null;
      }), Ae(e, "elementFromPoint", function (t, n, o) {
        var r;
        n = Math.max(n, 1);
        var i = [];
        if (!t) return i;
        var a,
          s = S.headerHeight(e._options),
          u = e._toScreenPx(e._val),
          c = 0,
          f = null === (r = e._options) || void 0 === r ? void 0 : r.timelineStyle;
        f && (c = Math.max((f.width || 1) * e._pixelRatio, ((null == f || null === (a = f.capStyle) || void 0 === a ? void 0 : a.width) || 0) * e._pixelRatio || 1) + n), (t.y <= .5 * s || t.x >= u - c / 2 && t.x <= u + c / 2) && i.push({
          val: e._val,
          type: w.Timeline
        });
        var v = e._options.snapEnabled;
        return t.y >= s && e._options.keyframesDraggable && e._forEachKeyframe(function (o, r, a) {
          var s = o.rowViewModel;
          a && s.groupsViewModels && s.groupsViewModels.forEach(function (n) {
            if (null != n && n.size && d.isOverlap(t.x, t.y, n.size)) {
              var o = (null == n ? void 0 : n.keyframesViewModels.map(function (e) {
                  return e.model;
                })) || [],
                r = {
                  val: e._mousePosToVal(t.x, v),
                  type: w.Group,
                  group: n.groupModel,
                  row: s.model,
                  keyframes: o
                },
                l = e.snapVal(n.min);
              r.val += n.min - l, i.push(r);
            }
          });
          var u = o.size;
          if (u) {
            var c = !1;
            if (o.shape === l.Rect) {
              var f = d.shrinkSelf({
                x: t.x,
                y: t.y,
                height: n,
                width: n
              }, n);
              c = d.isRectIntersects(f, u, !0);
            } else c = d.getDistance(u.x, u.y, t.x, t.y) <= u.height + n;
            c && i.push({
              keyframe: o.model,
              keyframes: [o.model],
              val: o.model.val,
              row: o.rowViewModel.model,
              type: w.Keyframe
            });
          }
        }, function (n) {
          if (d.isOverlap(t.x, t.y, n.size)) {
            var o = {
              val: e._mousePosToVal(t.x, v),
              keyframes: n.model.keyframes,
              type: w.Row,
              row: n.model
            };
            i.push(o);
          }
        }), o && 0 !== o.length ? i.filter(function (e) {
          return o && o.includes(e.type);
        }) : i;
      }), Ae(e, "onTimeChanged", function (t) {
        e.on(ke.TimeChanged, t);
      }), Ae(e, "onDragStarted", function (t) {
        e.on(ke.DragStarted, t);
      }), Ae(e, "onDrag", function (t) {
        e.on(ke.Drag, t);
      }), Ae(e, "onDragFinished", function (t) {
        e.on(ke.DragFinished, t);
      }), Ae(e, "onDoubleClick", function (t) {
        e.on(ke.DoubleClick, t);
      }), Ae(e, "onKeyframeChanged", function (t) {
        e.on(ke.KeyframeChanged, t);
      }), Ae(e, "onMouseDown", function (t) {
        e.on(ke.MouseDown, t);
      }), Ae(e, "onSelected", function (t) {
        e.on(ke.Selected, t);
      }), Ae(e, "onScroll", function (t) {
        e.on(ke.Scroll, t);
      }), Ae(e, "onScrollFinished", function (t) {
        e.on(ke.ScrollFinished, t);
      }), Ae(e, "onContextMenu", function (t) {
        e.on(ke.ContextMenu, t);
      }), Ae(e, "_emitScrollEvent", function (n, o) {
        var r,
          i,
          l = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : ke.Scroll,
          a = {
            args: n,
            scrollProgrammatically: o,
            scrollLeft: e.scrollLeft,
            scrollTop: e.scrollTop,
            scrollHeight: (null === (r = e._scrollContainer) || void 0 === r ? void 0 : r.scrollHeight) || 0,
            scrollWidth: (null === (i = e._scrollContainer) || void 0 === i ? void 0 : i.scrollWidth) || 0
          };
        return Ne(ze(t.prototype), "emit", e).call(e, l, a), a;
      }), Ae(e, "_emitKeyframeChanged", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : W.Programmatically,
          o = new V();
        return o.val = t.val, o.prevVal = t.prevVal, o.target = t, o.source = n, e.emit(ke.KeyframeChanged, o), o;
      }), Ae(e, "_emitDragStartedEvent", function (t) {
        if (!t) return null;
        var n = e._getDragEventArgs(t, e._currentPos);
        return e.emit(ke.DragStarted, n), n.isPrevented() && e._preventDrag(n, t, !0), n;
      }), Ae(e, "_emitDragFinishedEvent", function (t) {
        var n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        if (!t || !t.changed) return null;
        var o = e._getDragEventArgs(t, e._currentPos);
        return n && o.preventDefault(), e.emit(ke.DragFinished, o), o.isPrevented() && e._preventDrag(o, t, !0), o;
      }), Ae(e, "_preventDrag", function (t, n) {
        var o = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
        t.elements && t.elements.forEach(function (t) {
          var n = o ? t.startedVal : t.prevVal;
          e._setKeyframePos(t, n);
        }), n.val = n.prevVal, t.point = t.prevPoint;
      }), Ae(e, "_emitDragEvent", function (t) {
        if (!t) return null;
        var n = e._getDragEventArgs(t, e._currentPos);
        return e.emit(ke.Drag, n), n.isPrevented() && e._preventDrag(n, t, !1), n;
      }), Ae(e, "_emitKeyframesSelected", function (t) {
        var n = new ee();
        return n.selected = t.selected, n.changed = t.changed, e.emit(ke.Selected, n), n;
      }), Ae(e, "_getDragEventArgs", function (e, t) {
        var n = new me();
        return n.point = t, n.elements = function (e) {
          return function (e) {
            if (Array.isArray(e)) return Ee(e);
          }(e) || function (e) {
            if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
          }(e) || function (e, t) {
            if (e) {
              if ("string" == typeof e) return Ee(e, t);
              var n = {}.toString.call(e).slice(8, -1);
              return "Object" === n && e.constructor && (n = e.constructor.name), "Map" === n || "Set" === n ? Array.from(e) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? Ee(e, t) : void 0;
            }
          }(e) || function () {
            throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
          }();
        }(e.elements || []), n.target = (null == e ? void 0 : e.target) || null, n;
      }), e._options = d.cloneOptions(Pe), (n || o) && e.initialize(n, o), e;
    }
    return function (e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), t && je(e, t);
    }(t, e), n = t, o = [{
      key: "_getCtx",
      value: function () {
        return this._canvas ? (this._ctx || (this._ctx = this._canvas.getContext("2d")), this._ctx) : null;
      }
    }, {
      key: "_moveElements",
      value: function (e, t) {
        var n = this,
          o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : W.Programmatically;
        if (!t) return 0;
        var r = !1;
        if (Math.abs(e) > 0) {
          var i = {
            min: Number.MIN_SAFE_INTEGER,
            max: Number.MAX_SAFE_INTEGER
          };
          if (i = d.setMinMax(i, this._options), t.forEach(function (t) {
            var o;
            if (t && t.keyframe) {
              var r = d.setMinMax(d.setMinMax({
                  min: i.min,
                  max: i.max
                }, t.keyframe), t.row || null),
                l = (null !== (o = n._options) && void 0 !== o && o.snapAllKeyframesOnMove ? n.snapVal(t.keyframe.val) : t.keyframe.val) + e;
              (r.min || 0 === r.min) && d.isNumber(r.min) && l < r.min && (e += d.getDistance(r.min, l)), (r.max || 0 === r.max) && d.isNumber(r.max) && l > r.max && (e -= d.getDistance(r.max, l));
            }
          }), Math.abs(e) > 0 && t.forEach(function (t) {
            if (null != t && t.keyframe) {
              var i = t.keyframe.val,
                l = i + e,
                a = n._setKeyframePos(t, l, o);
              r = a !== i;
            }
          }), r) return e;
        }
        return 0;
      }
    }, {
      key: "_forEachKeyframe",
      value: function (e, t) {
        var n;
        if ((e || t) && this._model) {
          var o = this._generateViewModel();
          o && (null == o || null === (n = o.rowsViewModels) || void 0 === n || n.forEach(function (n) {
            if (n) {
              t && t(n);
              var o = !0;
              e && n.keyframesViewModels.forEach(function (t, n) {
                t && e(t, n, o), o = !1;
              });
            }
          }));
        }
      }
    }, {
      key: "_trackMousePos",
      value: function (e, t) {
        var n = this._getMousePos(e, t),
          o = n.pos;
        if (n.originalVal = this._mousePosToVal(o.x, !1), n.snapVal = this._mousePosToVal(o.x, !0), n.val = n.originalVal, this._options && this._options.snapEnabled && (n.val = n.snapVal), this._startPosMouseArgs) {
          var r, i;
          this._selectionRect || (this._selectionRect = {});
          var l = this._startPosMouseArgs.pos,
            a = Math.floor(l.x + (((null === (r = this._scrollStartPos) || void 0 === r ? void 0 : r.x) || 0) - this.scrollLeft)),
            s = Math.floor(l.y + (((null === (i = this._scrollStartPos) || void 0 === i ? void 0 : i.y) || 0) - this.scrollTop));
          this._selectionRect.x = Math.min(a, o.x), this._selectionRect.y = Math.min(s, o.y), this._selectionRect.width = Math.max(a, o.x) - this._selectionRect.x, this._selectionRect.height = Math.max(s, o.y) - this._selectionRect.y, this._clickAllowed && (this._clickAllowed = this._selectionRect.height <= this._consts.clickThreshold && this._selectionRect.width <= this._consts.clickThreshold);
        }
        return n;
      }
    }, {
      key: "getClientWidth",
      value: function () {
        var e;
        return (null === (e = this._scrollContainer) || void 0 === e ? void 0 : e.clientWidth) || 0;
      }
    }, {
      key: "getClientHeight",
      value: function () {
        var e;
        return (null === (e = this._scrollContainer) || void 0 === e ? void 0 : e.clientHeight) || 0;
      }
    }, {
      key: "_cleanUpSelection",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
        this._drag && this._emitDragFinishedEvent(this._drag, e), this._startPosMouseArgs = null, this._drag = null, this._scrollAreaClickOrDragStarted = !1, this._startedDragWithCtrl = !1, this._startedDragWithShiftKey = !1, this._selectionRect = null, this._clickTimeout = null, this._scrollStartPos = null, this._isPanStarted = !1, this._clickAllowed = !1, this._stopAutoPan();
      }
    }, {
      key: "_clickTimeoutIsOver",
      value: function () {
        return !!(this._clickTimeout && Date.now() - this._clickTimeout > this._consts.clickDetectionMs);
      }
    }, {
      key: "_checkUpdateSpeedTooFast",
      value: function () {
        return !!(this._autoPanLastActionDate && Date.now() - this._autoPanLastActionDate <= this._consts.autoPanSpeedLimit) || (this._autoPanLastActionDate = Date.now(), !1);
      }
    }, {
      key: "_scrollByPan",
      value: function (e, t, n) {
        if (e && t && this._scrollContainer) {
          var o = 0,
            r = 0;
          n && (o = n.x, r = n.y);
          var i = Math.round(e.x - t.x),
            l = o + i;
          i > 0 && this._rescaleInternal(l + this._canvasClientWidth()), i > 0 && l + this._canvasClientWidth() >= this._scrollContainer.scrollWidth - 5 ? this.scrollLeft = this._scrollContainer.scrollWidth : this.scrollLeft = l, this.scrollTop = Math.round(r + e.y - t.y);
        }
      }
    }, {
      key: "_scrollBySelectionOutOfBounds",
      value: function (e) {
        if (!this._scrollContainer) return !1;
        var t = e.x,
          n = e.y,
          o = !1,
          r = 0,
          i = 0,
          l = this._consts.autoPanByScrollPadding,
          a = t <= l,
          s = t >= this._canvasClientWidth() - l,
          u = n <= l,
          c = n >= this._canvasClientHeight() - l,
          f = null,
          v = null;
        if (a || s || u || c) {
          if (this._startAutoPan(), this._checkUpdateSpeedTooFast()) return !1;
          var y = isNaN(this._consts.scrollByDragSpeed) ? 1 : this._consts.scrollByDragSpeed;
          a ? r = -d.getDistance(t, l) * y : s && (r = d.getDistance(t, this._canvasClientWidth() - l) * y, f = this.scrollLeft + this._canvasClientWidth() + r), u ? i = -d.getDistance(t, l) * y / 4 : c && (i = d.getDistance(t, this._canvasClientHeight() - l) * y / 4, v = this._scrollContainer.scrollTop + this._canvasClientHeight());
        } else this._stopAutoPan();
        return (f || v) && this._rescaleInternal(f, v, Ce.ScrollBySelection), Math.abs(r) > 0 && (this.scrollLeft = this._scrollContainer.scrollLeft + r, o = !0), Math.abs(i) > 0 && (this.scrollTop = this._scrollContainer.scrollTop + i, o = !0), o;
      }
    }, {
      key: "pxToVal",
      value: function (e) {
        if (!this._options) return e;
        var t = this._options.min || 0;
        d.isNumber(t) || (t = 0);
        var n = this._options.stepPx || 0;
        return 0 === n ? e : (t *= this._currentZoom || 1) + e / n * ((this._options.stepVal || 0) * this._currentZoom || 1);
      }
    }, {
      key: "snapVal",
      value: function (e) {
        if (this._options && this._options.snapEnabled && this._options.snapStep) {
          var t = this._options.snapStep,
            n = e / t,
            o = Math.round(n),
            r = Math.abs(this._options.min || 0) / this._options.snapStep,
            i = d.sign(this._options.min || 1) * (r - Math.floor(r)) * this._options.snapStep;
          e = Math.round(i) + Math.round(o * t);
        }
        return d.keepInBounds(e, this._options.min, this._options.max);
      }
    }, {
      key: "scrollLeft",
      get: function () {
        var e;
        return (null === (e = this._scrollContainer) || void 0 === e ? void 0 : e.scrollLeft) || 0;
      },
      set: function (e) {
        this._scrollContainer && this._scrollContainer.scrollLeft !== e && (this._scrollProgrammatically = !0, this._scrollContainer.scrollLeft = e);
      }
    }, {
      key: "scrollTop",
      get: function () {
        var e;
        return (null === (e = this._scrollContainer) || void 0 === e ? void 0 : e.scrollTop) || 0;
      },
      set: function (e) {
        this._scrollContainer && this._scrollContainer.scrollTop !== e && (this._scrollProgrammatically = !0, this._scrollContainer.scrollTop = e);
      }
    }], o && De(n.prototype, o), Object.defineProperty(n, "prototype", {
      writable: !1
    }), n;
    var n, o;
  }(i);
  return t;
})());