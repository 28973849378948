<div class="loading" *ngIf="loading" id="loading-container">
    <div class="loading-container">
        <div>Loading editor</div>
        <p-progress-spinner ariaLabel="loading" />
    </div>
</div>
<div class="main-editor-container">
    <app-topbar></app-topbar>
    <!-- <app-action-bar></app-action-bar> -->
    <div class="editor-main">
        <div class="editor-left-menu" [class.leftMenuOpen]="leftMenu">
            <div class="top-menu">
                <mat-icon (click)="leftMenu = !leftMenu">{{leftMenu ? 'chevron_left' : 'chevron_right'}}</mat-icon>
            </div>
            <app-layerlist></app-layerlist>
        </div>

        <div class="editor-right-menu" [class.rightMenuOpen]="rightMenu">
            <div class="top-menu top-menu-right">
                <mat-icon (click)="rightMenu = !rightMenu">{{rightMenu ? 'chevron_right' : 'chevron_left'}}</mat-icon>
            </div>
            <app-layersetings></app-layersetings>
        </div>
    </div>
    <div class="editor-timeline-container" *ngIf="editorService.editorType == 'animate'">
        <app-timeline></app-timeline>
    </div>
</div>
<div style="height: 100%;">
    <app-layer-config *ngIf="editorService.overlay.settings && editorService.layersSelected" (onCloseSettings)="onCloseSettings($event)"></app-layer-config>
    <app-media-select *ngIf="editorService.overlay.drive" (onCloseMedia)="onCloseMedia($event)" [dataType]="editorService.overlay.driveTypes"></app-media-select>
    <app-text-style-editor *ngIf="commonService.textEditorOpen && commonService.textInputValue != null"></app-text-style-editor>
    <app-design-settings *ngIf="editorService.overlay.designsettings"></app-design-settings>
    <div class="saveoverlay-container" *ngIf="savingState > 0">
        <div class="saveoverlay">
            <div>
                <div class="create-screenshot">
                    <span class="material-symbols-outlined save-icons" [class.savedone]="savingState >= 1">
                        screenshot_tablet
                    </span>
                    <span>Screenshot</span>
                </div>
                <div class="save-devider"></div>
                <div class="uploading">
                    <span class="material-symbols-outlined save-icons" [class.savedone]="savingState >= 2">
                        cloud_upload
                    </span>
                    <span>Saving</span>
                </div>
                <div class="save-devider"></div>
                <div class="saved">
                    <span class="material-symbols-outlined save-icons" [class.savedone]="savingState >= 3">
                        cloud_done
                    </span>
                    <span>Saved</span>
                </div>
            </div>
        </div>
    </div>
</div>