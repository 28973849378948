import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopbarComponent } from './topbar/topbar/topbar.component';
import { LayerlistComponent } from './layerlist/layerlist/layerlist.component';
import { LayersetingsComponent } from './layersettings/layersetings/layersetings.component';
import { DynamicTableComponent } from './layers/table/dynamic-table/dynamic-table.component';
import { TableComponent } from './layers/table/table.component';
import { TimelineComponent } from './timeline/timeline/timeline.component';

import { EditorRoutingModule } from './editor-routing.module';
import { EditorComponent } from './editor.component';

import { SharedModule } from '../../shared/shared/shared.module';
import { LayerConfigComponent } from './components/layer-config/layer-config.component';
import { EditTableComponent } from './components/layer-config/edit-table/edit-table.component';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { DesignSettingsComponent } from './components/design-settings/design-settings.component';

@NgModule({
    declarations: [
        TopbarComponent,
        LayerlistComponent,
        LayersetingsComponent,
        TableComponent,
        DynamicTableComponent,
        TimelineComponent,
        EditorComponent,
        LayerConfigComponent,
        EditTableComponent,
        ActionBarComponent,
        DesignSettingsComponent,
    ],
    imports: [
        CommonModule,
        EditorRoutingModule,
        SharedModule
    ],
    exports: [
      SharedModule
    ]    
})
export class EditorModule { }
