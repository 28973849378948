import { Component } from '@angular/core';
import { EditorService } from '../../services/editor.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/api/api.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-design-settings',
  templateUrl: './design-settings.component.html',
  styleUrls: ['./design-settings.component.scss']
})
export class DesignSettingsComponent {

  themeGroups: any[] = [];
  themes: any[] = [];

  selectCustomGroup: boolean = false;
  selectCustomTheme: boolean = false;

  constructor(public editorService: EditorService, public authService: AuthService, private apiService: ApiService, private messageService: MessageService) {
    this.apiService.getDesignThemeGroups(this.authService.account.id).subscribe((res) => {
      this.themeGroups = res;
    });
    if(this.editorService.selectedDesign.themeName != ''){
      this.onThemeGroupChange();
    }
  }

  onThemeGroupChange() {
    if (this.editorService.selectedDesign.themeGroup == 'custom') {
      this.selectCustomGroup = true;
      this.editorService.selectedDesign.themeGroup = '';
    }
    this.apiService.getDesignThemes(this.authService.account.id, this.editorService.selectedDesign.themeGroup).subscribe((res) => {
      this.themes = res;
    });
  }

  onThemeChange() {
    if (this.editorService.selectedDesign.themeName == 'custom') {
      this.selectCustomTheme = true;
      this.editorService.selectedDesign.themeName = '';
    }
  }


  close() {
    this.editorService.overlay.designsettings = false;
  }

  updateGlobalDesign() {
    this.apiService.setDesignGlobal(this.authService.account.id, this.editorService.selectedDesign.id, this.editorService.selectedDesign.global_design).subscribe((res) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Global settings updated' });
    });
  }
}
