<div class="main-timeline">
    <div class="top-timeline">
        <div style="width: 20%;"></div>
        <mat-icon class="action-icons" (click)="createKeyFrame()">animation</mat-icon>
        <mat-icon [class.isAnimating]="editorService.isAnimating" class="action-icons" (click)="playAnimations()">play_arrow</mat-icon>
        <mat-icon class="action-icons" (click)="stopAnimations()">stop</mat-icon>
        <div class="ml-2">
            <input pInputText type="number" class="form-control designTime" [(ngModel)]="displayTime" style="text-align: center;">
        </div>
        <div style="width: 100%; display: flex; justify-content: flex-end;">
            <div style="display:flex;">
                <div style="display: flex; align-items: center;" *ngIf="editorService.layersSelected && editorService.animationSelectedGroup == 0 && editorService.selectedLayer.widget.childWidget.type == 3">
                    <div>Typing animation</div>
                    <mat-icon *ngIf="isCustomTypingAnimation()" class="action-icons" (click)="toggleAnimationCustom()">check_box</mat-icon>
                    <mat-icon *ngIf="!isCustomTypingAnimation()" class="action-icons" (click)="toggleAnimationCustom()">check_box_outline_blank</mat-icon>
                </div>
                <div style="display: flex; align-items: center;" *ngIf="editorService.layersSelected && editorService.animationSelectedGroup == 1">
                    <div>Auto end time</div>
                    <mat-icon *ngIf="editorService.selectedLayer.widget.useDisplayTime" class="action-icons" (click)="setUseDisplayTime()">check_box</mat-icon>
                    <mat-icon *ngIf="!editorService.selectedLayer.widget.useDisplayTime" class="action-icons" (click)="setUseDisplayTime()">check_box_outline_blank</mat-icon>
                </div>
                <div style="display: flex; align-items: center;" *ngIf="editorService.layersSelected && this.selectedKeyFrame">
                    <div>Wait frame</div>
                    <mat-icon *ngIf="editorService.selectedLayer.widget.playlistKeyFrameSwitch == this.selectedKeyFrame.keyFrameIndex" class="action-icons" (click)="setAnimationIndex()">check_box</mat-icon>
                    <mat-icon *ngIf="editorService.selectedLayer.widget.playlistKeyFrameSwitch != this.selectedKeyFrame.keyFrameIndex" class="action-icons" (click)="setAnimationIndex()">check_box_outline_blank</mat-icon>
                </div>

              <select class="form-select" [(ngModel)]="selectedAnimationCurve" (change)="updateAnimationCurve()" style="padding: 0;
              padding-right: 2em;
              padding-left: 0.5em;
              border: 0;
              width: 20em;
              border-radius: 0;">
                <option [value]="'linear'">Smooth</option>
                <option [value]="'fastOutSlowIn'">Quick Start, Gentle Finish</option>
                <option [value]="'slowMiddle'">Slow in the Middle</option>
                <option [value]="'bounceIn'">Bounce In</option>
                <option [value]="'bounceOut'">Bounce Out</option>
                <option [value]="'bounceInOut'">Bounce In and Out</option>
                <option [value]="'elasticIn'">Elastic In</option>
                <option [value]="'elasticOut'">Elastic Out</option>
                <option [value]="'elasticInOut'">Elastic In and Out</option>
                <option [value]="'ease'">Smooth Ease</option>
                <option [value]="'easeIn'">Ease In (Slow Start)</option>
                <option [value]="'easeInToLinear'">Ease In to Smooth</option>
                <option [value]="'easeInSine'">Ease In (Soft Sine)</option>
                <option [value]="'easeInQuad'">Ease In (Gradual Quad)</option>
                <option [value]="'easeInCubic'">Ease In (Slightly Faster)</option>
                <option [value]="'easeInQuart'">Ease In (Faster Start)</option>
                <option [value]="'easeInQuint'">Ease In (Quick Start)</option>
                <option [value]="'easeInExpo'">Ease In (Accelerated Start)</option>
                <option [value]="'easeInCirc'">Ease In (Circular)</option>
                <option [value]="'easeInBack'">Ease In (Overshoot)</option>
                <option [value]="'easeOut'">Ease Out (Smooth Finish)</option>
                <option [value]="'linearToEaseOut'">Smooth to Ease Out</option>
                <option [value]="'easeOutSine'">Ease Out (Soft Sine)</option>
                <option [value]="'easeOutQuad'">Ease Out (Gradual Quad)</option>
                <option [value]="'easeOutCubic'">Ease Out (Slightly Faster)</option>
                <option [value]="'easeOutQuart'">Ease Out (Faster Finish)</option>
                <option [value]="'easeOutQuint'">Ease Out (Quick Finish)</option>
                <option [value]="'easeOutExpo'">Ease Out (Accelerated Finish)</option>
                <option [value]="'easeOutCirc'">Ease Out (Circular)</option>
                <option [value]="'easeOutBack'">Ease Out (Overshoot)</option>
                <option [value]="'easeInOut'">Ease In and Out</option>
                <option [value]="'easeInOutSine'">Ease In and Out (Soft Sine)</option>
                <option [value]="'easeInOutQuad'">Ease In and Out (Gradual)</option>
                <option [value]="'easeInOutCubic'">Ease In and Out (Slightly Faster)</option>
                <option [value]="'easeInOutQuart'">Ease In and Out (Faster)</option>
                <option [value]="'easeInOutQuint'">Ease In and Out (Quick)</option>
                <option [value]="'easeInOutExpo'">Ease In and Out (Accelerated)</option>
                <option [value]="'easeInOutCirc'">Ease In and Out (Circular)</option>
                <option [value]="'easeInOutBack'">Ease In and Out (Overshoot)</option>
            </select>
            
            </div>
        </div>
    </div>
    <div class="timeline-container">
        <div class="left-timeline">
            <div [class.selectGroup]="editorService.animationSelectedGroup == i" class="timeline-animationgroup" *ngFor="let group of editorService.animationGroups; let i = index">
                <div (click)="selectLayerGroup(i)">{{group.name}}</div>
            </div>
        </div>
        <div class="left-timeline-layers">
            <div class="left-timeline-layers-padding"></div>
            <div [class.selectLayer]="layer.widget.selected" class="timeline-animation-layers" *ngFor="let layer of editorService.animationLayers;" (click)="editorService.selectLayer(layer)">
                <div style="overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;">{{layer.name}}</div>
            </div>
        </div>
        <div class="right-timeline" id="timeline"></div>
    </div>
</div>