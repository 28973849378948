<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Edit table</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="closeTable()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="config-main">
            <div class="flex flex-col gap-5">
                <div class="flex items-end gap-3" *ngFor="let item of displayedColumns; let i = index;">
                    <div>
                        <div>
                            {{item.layerText.text}}
                        </div>
                        <select class="form-select" id="input_selecttype_{{i}}" [(ngModel)]="item.selectedFieldType" (change)="onChangeField($event, item, i, true)">
                            <option *ngFor="let field of fields" [value]="field.field_class+'_'+field.name">{{field.field_class}} - {{field.name}}</option>
                        </select>
                    </div>
                    <div>
                        <select class="form-select" id="table_column_alignment_{{i}}" [(ngModel)]="item.vAlignment" (change)="onChangeField($event, item, i)">
                        <option [value]="'top'">Top</option>
                        <option [value]="'center'">Center</option>
                        <option [value]="'bottom'">Bottom</option>
                      </select>
                    </div>
                    <div>
                        <p-floatlabel>
                            <label for="table_column_width_{{i}}" class="form-label">Width</label>
                            <input pInputText id="table_column_width_{{i}}" type="number" class="form-control w-full" placeholder="Column width" [(ngModel)]="item.width" (change)="onChangeField($event, item, i)">
                        </p-floatlabel>
                    </div>
                    <div *ngIf="i == 0">
                        <p-floatlabel>
                            <label for="table_column_padding_{{i}}" class="form-label">Padding</label>
                            <input pInputText id="table_column_padding_{{i}}" type="number" class="form-control w-full" placeholder="Padding" [(ngModel)]="item.padding" (change)="onChangeField($event, item, i)">
                        </p-floatlabel>
                    </div>
                    <div class="flex items-center h-10 w-full justify-end">
                        <ng-icon class="cursor-pointer" name="saxTrashOutline" size="25" (click)="deleteColumn(i)"></ng-icon>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>