<div *ngIf="editorService.layersSelected">
    <div class="position">
        <div><span>Left:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.left" (change)="onLeftChange($event)"></div>
        <div><span>Top:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.top" (change)="onTopChange($event)"></div>
    </div>
    <div class="position">
        <div><span>Width:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.width" (change)="onWidthChange($event)"></div>
        <div><span>Height:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.height" (change)="onHeightChange($event)"></div>
    </div>
    <div class="position">
        <div><span>Skew x:</span><input pInputText type="number" step="0.01" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.skewX" (change)="onSkewxChange($event)"></div>
        <div><span>Skew Y:</span><input pInputText type="number" step="0.01" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.skewY" (change)="onSkewyChange($event)"></div>
    </div>
    <div class="position">
        <div><span>Opacity:</span><input pInputText type="number" step="0.01" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.opacity" (change)="onOpacityChange($event)"></div>
        <div><span>Angle:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.angle" (change)="onAngleChange($event)"></div>
    </div>
    <div class="position">
        <div><span>Border size:</span><input pInputText type="number" step="0.01" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.borderSize" (change)="onBorderSizeChange($event)"></div>
        <div><span>Border radius:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.borderRadius" (change)="onBorderRadiusChange($event)"></div>
    </div>
    <div class="position">
        <div style="display: flex;"><span>Circle:</span><p-checkbox [ngModel]="editorService.selectedLayer.widget.settings.isCircle" (change)="onIsCircleChange($event)" [binary]="true" /></div>
        <div><span>Blur:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.blurValue" (change)="onBlurValueChange($event)"></div>
    </div>
    <div class="position">
        <div><span>Color:</span><input pInputText type="text" class="form-control" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="color" [style.background]="color" (colorPickerChange)="onTextColorChange($event)"></div>
        <div><span>Border Color:</span><input pInputText type="text" class="form-control" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="borderColor" [style.background]="borderColor" (colorPickerChange)="onBorderColorChange($event)"></div>
    </div>
    <div class="position">
        <div style="display: flex;"><span>Shadow:</span><p-checkbox [ngModel]="editorService.selectedLayer.widget.settings.boxShadowEnabled" (change)="onBoxShadowEnabledChange($event)" [binary]="true" /></div>
    </div>
    <div class="position">
        <div><span>Shadow X:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.boxShadowOffSetX" (change)="onBoxShadowOffSetXChange($event)"></div>
        <div><span>Shadow Y:</span><input pInputText type="number" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.boxShadowOffSetY" (change)="onBoxShadowOffSetYChange($event)"></div>
    </div>
    <div class="position">
        <div><span>Shadow blur:</span><input pInputText type="number" step="0.01" class="form-control" [ngModel]="editorService.selectedLayer.widget.settings.boxShadowSpreadRadius" (change)="onBoxShadowBlurChange($event)"></div>
        <div><span>Shadow color:</span><input pInputText type="text" class="form-control" [cpDialogDisplay]="'popup'" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="boxShadow" [style.background]="boxShadow" (colorPickerChange)="onBoxShadowChange($event)"></div>
    </div>
</div>