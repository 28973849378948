<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Settings</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="onClose()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="config-main">
            <div class="v-menu-container">
                <div class="v-menu-button" (click)="selectMenuOption(selectedMenuOptions.main)" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.main">Settings</div>
                <div *ngIf="type ==types.table" class="v-menu-button" (click)="selectMenuOption(selectedMenuOptions.table)" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.table">Table</div>
                <div *ngIf="type ==types.text" class="v-menu-button" (click)="selectMenuOption(selectedMenuOptions.text)" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.text">Text</div>
                <div *ngIf="type ==types.playlist" class="v-menu-button" (click)="selectMenuOption(selectedMenuOptions.playlist)" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.playlist">Playlist</div>
            </div>
            <div class="config-body pt-3">
                <div *ngIf="selectedMenu == selectedMenuOptions.main">
                    <div>
                        <div class="flex flex-col gap-2 pt-2">
                            <div class="flex flex-col gap-2 items-start">
                                <label for="layer_name_field" class="font-thin text-sm">Layer Name</label>
                                <input id="layer_name_field" pInputText class="form-control" [(ngModel)]="editorService.selectedLayer.name" (change)="updateLayer()">
                            </div>
                            <div class="flex gap-4">
                                <div class="flex flex-col gap-2 items-start">
                                    <label for="layer_type_field" class="font-thin text-sm">Layer Type</label>
                                    <select id="layer_type_field" class="form-select" [(ngModel)]="editorService.selectedLayer.classType" (change)="updateLayer()">
                                        <option *ngFor="let fieldClass of fieldClasses" [value]="fieldClass.name" >{{fieldClass.name}}</option>
                                    </select>
                                </div>
                                <div class="flex flex-col gap-2 items-start">
                                    <label for="parent_layer_field" class="font-thin text-sm">Parent layer</label>
                                    <select id="parent_layer_field" class="form-select" [(ngModel)]="editorService.selectedLayer.parentId" (change)="onChangeField($event)">
                                        <option [value]="0">None</option>
                                        <option *ngFor="let layer of parentLayers" [value]="layer.id">{{layer.name}}</option>
                                    </select>
                                </div>
                                <div class="flex flex-col gap-2 items-start" *ngIf="columnNames.length > 0">
                                    <label for="parent_selected_column_field" class="font-thin text-sm">Selected column</label>
                                    <select id="parent_selected_column_field" class="form-select" [(ngModel)]="editorService.selectedLayer.columnId" (change)="onChangeField($event)">
                                        <option *ngFor="let columnName of columnNames; let i = index;" [value]="i">{{columnName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex flex-col gap-2 items-start" *ngIf="editorService.selectedLayer.classType == 'Feed' || editorService.selectedLayer.classType == 'Table'">
                                <label for="layer_field" class="font-thin text-sm">Layer Field</label>
                                <select id="layer_field" class="form-select" [(ngModel)]="editorService.selectedLayer.selectedFieldType" (change)="onChangeField($event)">
                                    <option *ngFor="let field of fields" [value]="field.field_class+'_'+field.name">{{field.field_class}} - {{field.name}}</option>
                                </select>
                            </div>
                           
                            <div class="flex gap-4">
                                <div class="flex flex-col gap-2 items-start">
                                    <label for="dynamic_field" class="font-thin text-sm">Dynamic</label>
                                    <select id="dynamic_field" class="form-select" [(ngModel)]="editorService.selectedLayer.isDynamic" (change)="updateLayer()">
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                                <div class="flex flex-col gap-2 items-start">
                                    <label for="required_field" class="font-thin text-sm">Required</label>
                                    <select id="required_field" class="form-select" [(ngModel)]="editorService.selectedLayer.required" (change)="updateLayer()">
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedMenu == selectedMenuOptions.table" style="padding-right: 2em;">
                    <div>
                        <div class="mat-elevation-z8">
                            <div class="top-buttons mb-2">
                                <button [outlined]="true" pButton (click)="addColumn()">Add Column</button>
                                <button [outlined]="true" pButton (click)="addRow()">Add Row</button>
                                <button [outlined]="true" pButton (click)="onEditTable()">Edit</button>
                                <inputfield class="flex" *ngIf="tableDataSource.length > 0" [global]="true" [column]="i" [row]="0" [isColumn]="true" (onChange)="updateTable($event)" [(inputValue)]="tableDataSource[0][0].layerText"></inputfield>
                            </div>
                            <table class="table table-striped table-layer">
                                <thead>
                                    <tr>
                                        <th *ngFor="let header of displayedColumns; let i = index">
                                            <inputfield  [column]="i" [row]="0" [isColumn]="true" (onChange)="updateTable($event)" [(inputValue)]="header.layerText"></inputfield>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of tableDataSource; let i = index">
                                        <td *ngFor="let cell of row; let i2 = index">
                                            <inputfield [column]="i2" [row]="i" [isColumn]="false" (onChange)="updateTable($event)" [(inputValue)]="cell.layerText"></inputfield>
                                        </td>
                                        <td>
                                            <button tabindex="-1" mat-icon-button (click)="deleteRow(i)">
                                                <ng-icon name="saxTrashOutline" size="25"></ng-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.text">
                        <h3>Text</h3>
                        <div></div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.playlist">
                        <h3>Playlist</h3>
                        <div></div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.design">
                        <h3>Design</h3>
                        <div></div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.dynamic">
                        <h3>Dynamic</h3>
                        <div></div>
                    </div>
                </div>

                <div *ngIf="selectedMenu == selectedMenuOptions.text">
                    <div>
                        <div class="mat-elevation-z8">
                            <table class="table table-striped">
                                <tr>
                                    <td>
                                        <inputfield [autoFocus]="true" (onChange)="updateTextLayer($event)" [(inputValue)]="textLayer"></inputfield>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedMenu == selectedMenuOptions.playlist" class="playlist-settings-container">
                    <div>
                        <div class="mat-elevation-z8">
                            <div class="top-buttons" style="padding: 0.5em;">
                                <div class="mb-3" style="display: flex; align-items: center;">
                                    <b>Display time:</b>
                                    <input pInputText style="width: 5em;" type="number" class="form-control" [(ngModel)]="this.currentPlaylist.time" (change)="updatePlaylist()">
                                </div>
                                <button pButton (click)="addPlaylistItems()">Add</button>
                            </div>
                            <div class="playlist-list">
                                <table class="table table-striped">
                                    <tbody cdkDropList (cdkDropListDropped)="playlistDrop($event)">
                                        <tr style="width: 30em;" cdkDrag *ngFor="let item of this.currentPlaylist.items; let pi = index">

                                            <td style="width: 80%;">
                                                <div class="playlist-item">
                                                    <img crossorigin="anonymous" class="playlist-image" src="{{item.data.url_small}}" />
                                                </div>
                                            </td>
                                            <td style="width: 20%;">
                                                <div class="playlist-action">
                                                    <mat-icon class="icon" (click)="removePlaylistItem(pi)">
                                                        delete
                                                    </mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <app-edit-table *ngIf="editTable" (onCloseTable)="onCloseTable($event)" (onUpdateRowStyle)="onUpdateRowStyle($event)" (onColmnUpdate)="onColmnUpdate($event)" (onColumnDelete)="onColumnDelete($event)" [tableDataSource]="tableDataSource" [displayedColumns]="displayedColumns"></app-edit-table>
</div>