<div class="topbar-container">
    <button class="main-menu" (click)="back()">
      <ng-icon name="saxArrowLeft2Outline" size="25"></ng-icon>
    </button>
    <div class="flex">
        <p-popover class="popover-el" #editormenu>
            <div class="popover-buttons flex flex-col">
                <button class="action-button" severity="secondary" pButton (click)="openSettings(); editormenu.toggle($event)">Settings</button>
                <button class="action-button" severity="secondary" pButton (click)="load(); editormenu.toggle($event)">Load</button>
                <button class="action-button" severity="secondary" pButton (click)="save(); editormenu.toggle($event)">Save</button>
            </div>
        </p-popover>
        <button class="main-menu" (click)="editormenu.toggle($event)">
            <ng-icon name="saxMenu1Outline" size="25"></ng-icon>
        </button>
        <p-popover class="popover-el" #editorlayermenu>
            <div class="popover-buttons flex flex-col">
                <button class="action-button" severity="secondary" pButton (click)="createTextLayer(); editorlayermenu.toggle($event)">Text</button>
                <button class="action-button" severity="secondary" pButton (click)="createImageLayer(); editorlayermenu.toggle($event)">Image</button>
                <button class="action-button" severity="secondary" pButton (click)="createVideoLayer(); editorlayermenu.toggle($event)">Video</button>
                <!-- <button class="action-button" severity="secondary" pButton (click)="createPlaylistLayer()">Playlist</button> -->
                <button class="action-button" severity="secondary" pButton (click)="createTableLayer(); editorlayermenu.toggle($event)">Table</button>
                <button class="action-button" severity="secondary" pButton (click)="createWebviewLayer(); editorlayermenu.toggle($event)">Webpage (android)</button>
            </div>
        </p-popover>
        <button class="main-menu" (click)="editorlayermenu.toggle($event)">
          <ng-icon name="saxAdditemOutline" size="25"></ng-icon>
        </button>
    </div>
    <div class="action-buttons-container">
        <div class="action-buttons">
            <button class="flex items-center" [disabled]="editorService.history.length == 0" (click)="editorService.undo()">
              <ng-icon name="saxUndoOutline" size="20"></ng-icon>
            </button>
            <button class="flex items-center" [disabled]="editorService.selectedLayers.length == 0" (click)="editorService.addToCopyList()">
              <ng-icon name="saxRedoOutline" size="20"></ng-icon>
            </button>
            <button class="flex items-center" [disabled]="editorService.selectedForCopy.length == 0" (click)="editorService.copySelectedLayers()">
              <ng-icon name="saxCopyOutline" size="20"></ng-icon>
            </button>
            <button class="flex items-center" [disabled]="editorService.selectedLayers.length == 0" (click)="editorService.deleteSelectedLayers()">
              <ng-icon name="saxTrashOutline" size="20"></ng-icon>
            </button>
            <!-- <button class="main-menu-edit-layer" [class]="!editorService.isSnapToLayer ? 'snapton' : ''"  (click)="editorService.setSnapToLayer(editorService.isSnapToLayer)">
              <mat-icon>layers</mat-icon>
            </button>
            <button class="main-menu-edit-layer" [class]="!editorService.isSnapToGrid ? 'snapton' : ''"  (click)="editorService.setSnapToGrid(editorService.isSnapToGrid)">
              <mat-icon>grid_on</mat-icon>
            </button> -->
            <button class="flex items-center" [disabled]="editorService.selectedLayers.length == 0" (click)="editorService.overlay.settings = true">
              <ng-icon name="saxEditOutline" size="20"></ng-icon>
            </button>
        </div>
    </div>


    <div class="menu-right">
        <select class="form-select" [(ngModel)]="editorService.zoom" (change)="changeZoom(editorService.zoom)">
            <option [value]="1">100%</option>
            <option [value]="0.9">90%</option>
            <option [value]="0.8">80%</option>
            <option [value]="0.75">75%</option>
            <option [value]="0.7">70%</option>
            <option [value]="0.6">60%</option>
            <option [value]="0.5">50%</option>
            <option [value]="0.4">40%</option>
            <option [value]="0.3">30%</option>
            <option [value]="0.25">25%</option>
            <option [value]="0.1">10%</option>
        </select>

        <div class="tab-index">
            <div class="tab-item" (click)="editorService.editorType = editorTypes.design; onEditorTypeChange(editorTypes.design)" [class.tab-item-selected]="editorService.editorType == editorTypes.design">Design</div>
            <div class="tab-item" (click)="editorService.editorType = editorTypes.animate; onEditorTypeChange(editorTypes.animate)" [class.tab-item-selected]="editorService.editorType == editorTypes.animate">Animate</div>
        </div>
    </div>
</div>