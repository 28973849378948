import { Component, ViewEncapsulation } from '@angular/core';
import { EditorTypes } from '../../enum/editor-types';
import { LayerTableColumn } from '../../models/layer-table-column';
import { LayerTableRow } from '../../models/layer-table-row';
import { EditorService } from '../../services/editor.service';
import { TextLayer } from '../../class/text-layer';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialog } from 'src/app/popups/confirm/dialog';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
})
export class TopbarComponent {
  editorTypes = EditorTypes;
  constructor(public editorService: EditorService, private authService: AuthService, private router: Router, private dialog: MatDialog) {

  }

  onEditorTypeChange(value: any) {
    if (value == EditorTypes.design) {
      this.editorService.onDesignMode();
    } else {
      this.editorService.onAnimationMode();
    }
  }

  back() {
    if(!this.editorService.unsavedChanges){
      this.router.navigate([
        this.authService.getAccountBasePath() + '/designs'
      ]);
    }else{
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Unsaved changes',
          message: 'You have unsaved changes. Do you want to leave without saving?',
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.editorService.unsavedChanges = false;
          this.router.navigate([
            this.authService.getAccountBasePath() + '/designs'
          ]);
        }
      });
    }
  }

  save() {
    this.editorService.save();
  }

  load() {
    this.editorService.load();
  }

  openSettings() {
    this.editorService.overlay.designsettings = true;
  }

  changeZoom(zoom: any) {
    zoom = parseFloat(zoom);
    //@ts-ignore
    LayerAPI.zoom(zoom);
    this.editorService.zoom = zoom;
  }

  createImageLayer() {
    this.editorService.overlay.driveTypes = ['Folder', 'Image'];
    this.editorService.overlay.drive = true;
  }

  createTableLayer() {
    let colums: LayerTableColumn[] = [];
    let rows: Array<LayerTableRow[]> = [];

    this.editorService.createTableLayer(colums, rows, "Table " + this.editorService.layers.length + 1);
  }

  createTextLayer() {
    let textLayer = new TextLayer();
    textLayer.text = 'Double click to edit text';
    this.editorService.createTextLayer(textLayer, "Text " + this.editorService.layers.length + 1);
  }

  createVideoLayer() {
    this.editorService.overlay.driveTypes = ['Folder', 'Video'];
    this.editorService.overlay.drive = true;
  }

  createPlaylistLayer() {
    this.editorService.createPlaylistLayer("Playlist " + this.editorService.layers.length + 1);
  }

  createWebviewLayer() {
    this.editorService.createWebViewLayer({
      url: "https://www.google.com",
      allowedUrls: ["*"],
      resetTimer: 0
    }, "Webview " + this.editorService.layers.length + 1);
  }
}
